import React from 'react';
import styles from './TermsOfUse.module.scss';
import { SecondaryButton, PrimaryButton } from 'src/shared/components/button/Button';

import useAuthService from 'src/shared/hooks/useAuthService';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

const TermsOfUseDeclined = () => {
    const { signout } = useAuthService();
    const navigate = useAppNavigation();

    const handleReturnToTerms = () => {
        navigate.toTermsOfUse();
    };

    const handleLogout = () => {
        signout();
    };

    return (
        <div className={styles.termsOfUseDeclined}>
            <div>Unfortunately you can not access the NatureMetrics platform without accepting the terms of use.</div>
            <div className={styles.btnGroup}>
                <SecondaryButton onClick={handleReturnToTerms}>Return to Terms of Use</SecondaryButton>
                <PrimaryButton onClick={handleLogout}>Log out</PrimaryButton>
            </div>
        </div>
    );
};

export default TermsOfUseDeclined;
