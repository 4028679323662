import React from 'react';
import Select, { SelectOption } from 'src/shared/components/select/Select';

import styles from './DateFilter.module.scss';

export type SelectedDateRange = {
    startDate: string;
    endDate: string;
};

type DateFilterProps = {
    selectedDateRange: SelectedDateRange;
    startDates: SelectOption[];
    endDates: SelectOption[];
    onChange: (dateRange: SelectedDateRange) => void;
};

const DateFilter = (props: DateFilterProps) => {
    const { startDates, endDates, onChange, selectedDateRange } = props;

    return (
        <div className={styles.nowrap}>
            <span>Displaying from</span>
            <span>
                <Select
                    selectedValue={selectedDateRange.startDate}
                    onChange={value => onChange({ ...selectedDateRange, startDate: value })}
                    options={startDates}
                />
            </span>
            <span>to</span>
            <span>
                <Select
                    selectedValue={selectedDateRange.endDate}
                    onChange={value => onChange({ ...selectedDateRange, endDate: value })}
                    options={endDates}
                />
            </span>
        </div>
    );
};

export default DateFilter;
