import React, { ReactElement, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import Loader from 'src/shared/components/loader/Loader';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

type ProtectedRouteProps = {
    redirectPath: string;
    redirectDelay?: number;
    children: ReactElement;
    isAllowed: boolean;
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
    const { redirectPath, isAllowed, children, redirectDelay = 2000 } = props;
    const isAllowedRef = useRef(false);
    const navigate = useAppNavigation();

    useEffect(() => {
        isAllowedRef.current = isAllowed;
    }, [isAllowed]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isAllowedRef.current !== true) {
                navigate.to(redirectPath, {
                    replace: true,
                });
            }
        }, redirectDelay);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    if (!isAllowed) {
        return <Loader />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
