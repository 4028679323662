import React, { ReactNode } from 'react';
import Breadcrumbs, { Breadcrumb } from '../../breadcrumbs/Breadcrumbs';
import classNames from 'classnames';

import styles from './StandardAppLayout.module.scss';
import DemoProjectBanner from '../../demo-project-banner/DemoProjectBanner';
import { AppName } from 'src/global/globalConfig';

const demoBannerPages = [
    AppName.DASHBOARD,
    AppName.MAP,
    AppName.COMMUNITY_COMPARISON,
    AppName.ECOLOGICAL_TRENDS,
    AppName.SAMPLE_COMPARISON,
    AppName.STATISTICAL_COMPARISON,
    AppName.TAXONOMIC_COMPOSITION,
    AppName.SAMPLE_METRICS_TABLE,
];

type StandardAppLayoutProps = {
    title?: string;
    subTitle?: ReactNode;
    breadcrumbs?: Breadcrumb[];
    otherActions?: ReactNode;
    mainContent?: ReactNode;
    lozenges?: ReactNode;
    filterSection?: ReactNode;
    classNames?: {
        mainContent?: string;
    };
    page?: AppName;
};

export const StandardAppLayout = (props: StandardAppLayoutProps) => {
    const {
        title,
        subTitle,
        breadcrumbs,
        otherActions,
        mainContent,
        lozenges,
        filterSection,
        classNames: classNamesFromProps,
        page,
    } = props;
    const shouldShowTopContent = title || subTitle || lozenges || otherActions || breadcrumbs;

    const mainContentClassNames = classNames(styles.mainContent, {
        [classNamesFromProps?.mainContent || '']: classNamesFromProps?.mainContent,
    });

    return (
        <>
            <div className={styles.standardAppLayout}>
                {page && demoBannerPages.includes(page) && <DemoProjectBanner pageName={page} />}
                {shouldShowTopContent && (
                    <div className={styles.topContent}>
                        <div className={styles.titleBar}>
                            {title && <h2 className={styles.title}>{title}</h2>}
                            {subTitle && <h4 className={styles.subTitle}>{subTitle}</h4>}
                            {breadcrumbs && (
                                <div className={styles.breadcrumbs}>
                                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                                </div>
                            )}
                        </div>
                        <div className={styles.filters}>
                            <div className={styles.habitatAssay}>{lozenges || null}</div>
                            <div className={styles.otherActions}>{otherActions || null}</div>
                        </div>
                    </div>
                )}
                <div className={styles.filters} data-testid='filter-section'>
                    {filterSection || null}
                </div>

                <div className={mainContentClassNames} data-testid='main-content'>
                    {mainContent || null}
                </div>
            </div>
        </>
    );
};

export default StandardAppLayout;
