import React from 'react';

import BasicInformation from './components/basic-information/BasicInformation';
import CustomerContacts from './components/customer-contacts/CustomerContacts';
import useProjectDefinition from 'src/app/projects/views/new-project-definition/hooks/useProjectDefinition';
import styles from 'src/app/projects/views/new-project-definition/NewProjectDefinition.module.scss';

export const ProjectDetails = () => {
    const { canCurrentUserContinueForm } = useProjectDefinition();

    return (
        <div className={styles.formView}>
            <div className={styles.formViewTitle}>Project details</div>
            <BasicInformation />

            {canCurrentUserContinueForm && <CustomerContacts />}
        </div>
    );
};

export default ProjectDetails;
