import once from 'lodash/once';
import config from 'src/config';

/**
 * References:
 * https://developers.google.com/analytics/devguides/collection/analyticsjs
 */

interface GTag {
    (...args: any[]): void; // executes analytics commands
}

// extend the window interface with the google analytics object
declare global {
    interface Window {
        gtag: GTag;
        dataLayer: unknown[];
    }
}

const loadGoogleAnalytics = (trackerId: string) => {
    loadScript(trackerId);
    createGAShim(trackerId);
};

// create a simplified google analytics object and assign it to window
// to keep a queue of any pending analytics commands
// until the real google analytics object is ready to replace it
const createGAShim = (trackerId: string) => {
    window.gtag = function () {
        window.dataLayer.push(arguments); // eslint-disable-line prefer-rest-params
    };

    window.dataLayer = window.dataLayer || []; // initialise the command queue
    window.gtag('js', new Date()); // Google uses this for timing hits

    window.gtag('config', trackerId);

    // If the deployment environment is not production, set debug_mode to true
    if (config.deploymentEnv !== 'prod') {
        window.gtag('config', trackerId, { debug_mode: true });
    }
};

const loadScript = (trackerId: string) => {
    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${trackerId}`;
    document.head.appendChild(scriptElement);
};

export default once(loadGoogleAnalytics);
