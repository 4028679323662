import { gql } from 'graphql-request';
import { Project } from 'src/shared/types';

type IucnMetricsData = Pick<Project, 'projectId' | 'projectName' | 'projectStartDate' | 'latestReportingDate' | 'taxonomySummary'>;

export const iucnMetricsQuery = gql`
    query get_project_data($projectId: String!, $samples: sampleFilter) {
        iucnMetrics: project(projectId: $projectId, samples: $samples) {
            projectId
            projectName
            taxonomySummary
            projectStartDate
            latestReportingDate
        }
    }
`;

export type IucnMetricsQueryResult = {
    iucnMetrics: IucnMetricsData;
};
