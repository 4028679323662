import React from 'react';

import BasicInformation from './components/basic-information/BasicInformation';
import CustomerContacts from './components/customer-contacts/CustomerContacts';
import useSurveyDesign from 'src/app/surveys/views/new-survey-design/hooks/useSurveyDesign';
import styles from 'src/app/surveys/views/new-survey-design/NewSurveyDesign.module.scss';

export const SurveyDesignDetails = () => {
    const { canCurrentUserContinueForm } = useSurveyDesign();

    return (
        <div className={styles.formView}>
            <div className={styles.formViewTitle}>Survey details</div>
            <BasicInformation />

            {canCurrentUserContinueForm && <CustomerContacts />}
        </div>
    );
};

export default SurveyDesignDetails;
