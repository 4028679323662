import React, { useState } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { useAppDispatch, useAppSelector } from 'src/store';
import { NewSurveyDesignFormState, setFormState } from 'src/app/surveys/views/new-survey-design/state/newSurveyDesignSlice';
import { getCurrentSurveyDesignFormState } from 'src/app/surveys/views/new-survey-design/state/newSurveyDesignSelector';
import Input from 'src/shared/components/input/Input';
import { availableAreaColors } from 'src/shared/constants/colors';
import { PrimaryButton } from 'src/shared/components/button/Button';
import commonStyles from 'src/app/surveys/views/new-survey-design/NewSurveyDesign.module.scss';
import styles from './SampleGroups.module.scss';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';

const SampleGroups = () => {
    const dispatch = useAppDispatch();
    const formState = useAppSelector(getCurrentSurveyDesignFormState);
    const { sampleGroups } = formState;
    const [sampleGroupValue, setSampleGroupValue] = useState('');

    const onFormChange = (fragment: Partial<NewSurveyDesignFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    const addSampleGroup = () => {
        if (sampleGroups.includes(sampleGroupValue)) {
            return;
        }

        onFormChange({
            sampleGroups: [...sampleGroups, sampleGroupValue],
        });
        setSampleGroupValue('');
    };

    const removeSampleGroup = (value: string) => {
        onFormChange({
            sampleGroups: [...sampleGroups].filter(item => item !== value),
        });
    };

    return (
        <div className={commonStyles.formSection}>
            <div className={commonStyles.formSectionTitle}>Sample groups</div>
            <div className={commonStyles.formInputSection}>
                <div className={commonStyles.formInputSectionTitle}>
                    Sample groups <QuestionButtonHelp type='api' slug='project-definition-sample-groups' />
                </div>

                <div className={styles.addSampleGroup}>
                    <span
                        className={styles.colorIndicator}
                        style={
                            {
                                '--sample-group-input-area-indicator-color': availableAreaColors[sampleGroups.length],
                            } as React.CSSProperties
                        }
                    ></span>
                    <Input
                        className={styles.sampleGroupInput}
                        value={sampleGroupValue}
                        onChange={event => setSampleGroupValue(event.target.value)}
                        maxLength={25}
                        placeholder='Type sample group name'
                    />

                    {sampleGroups.length < 8 && (
                        <PrimaryButton className={commonStyles.addButton} onClick={addSampleGroup} disabled={!sampleGroupValue}>
                            <span>+</span> ADD
                        </PrimaryButton>
                    )}
                </div>
                <div className={styles.sampleGroups}>
                    {sampleGroups.map((entry, index) => {
                        return (
                            <div key={index} className={styles.sampleGroupTag}>
                                <div className={styles.sampleGroupTagColor} style={{ backgroundColor: availableAreaColors[index] }}></div>
                                <div className={styles.sampleGroupTagValue}>{entry}</div>
                                <ClearOutlinedIcon onClick={() => removeSampleGroup(entry)} />
                            </div>
                        );
                    })}
                </div>
                {sampleGroups.length > 0 && <div className={commonStyles.maximumText}>Maximum 8 sample groups</div>}
            </div>
        </div>
    );
};

export default SampleGroups;
