import React, { useState } from 'react';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';

import useAuthService from 'src/shared/hooks/useAuthService';
import styles from './Signin.module.scss';
import PasswordInput from 'src/shared/components/password-input/PasswordInput';
import { PrimaryButton } from 'src/shared/components/button/Button';

const SetNewPassword = () => {
    const { changePassword } = useAuthService();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
        } else {
            changePassword({
                password: confirmPassword,
                onFailure: setErrorMessage,
            });
        }
    };
    return (
        <div className={styles.formContainer}>
            <div className={styles.subTitle}>Create a new password</div>
            <div className={styles.passwordRules}>
                <span>Your new password must contain at least:</span>
                <ul>
                    <li>8 characters</li>
                    <li>One upper case</li>
                    <li>One special character (!@&*)</li>
                    <li>One number</li>
                </ul>
            </div>
            <form className={styles.forgotPasswordForm} onSubmit={handleSubmit}>
                <PasswordInput placeholder='Password *' value={password} onChange={e => setPassword(e.target.value)} required />

                <PasswordInput
                    placeholder='Confirm Password *'
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    required
                />

                <PrimaryButton type='submit'>Confirm</PrimaryButton>
                <div className={styles.errorMessage}>{errorMessage && <ErrorMessage message={errorMessage} />}</div>
            </form>
        </div>
    );
};

export default SetNewPassword;
