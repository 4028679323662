import { Action, createAsyncThunk, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { fetchCustomers } from './api/customersGraphSlice';
import { RootState } from 'src/store';
import customersStorageService from '../services/customers-storage-service';
import { Customer } from 'src/shared/types';

type CustomerState = {
    currentCustomerId: string | null;
    allCustomers: Pick<Customer, 'customerId' | 'customerName'>[];
};
const initialState: CustomerState = {
    currentCustomerId: null,
    allCustomers: [],
};

export const setCurrentCustomerId =
    (Customer: string): ThunkAction<void, RootState, void, Action<string>> =>
    dispatch => {
        dispatch(customerSlice.actions.setCurrentCustomerId(Customer));

        customersStorageService.setCurrentCustomerId(Customer);
    };

export const loadStoredCustomerId = createAsyncThunk('customers/loadStoredCustomerId', async () => {
    return customersStorageService.getCurrentCustomerId();
});

export const resetCustomers = (): ThunkAction<void, RootState, void, Action<string>> => dispatch => {
    dispatch(customerSlice.actions.reset());

    customersStorageService.clearAll();
};

const customerSlice = createSlice({
    name: 'Customer',
    initialState,
    reducers: {
        setCurrentCustomerId(state, action: PayloadAction<string>) {
            state.currentCustomerId = action.payload;
        },
        reset(state) {
            // eslint-disable-next-line
            state = { ...initialState };
        },
    },
    extraReducers: builder => {
        builder.addCase(loadStoredCustomerId.fulfilled, (state, action) => {
            state.currentCustomerId = action.payload;
        });

        builder.addMatcher(fetchCustomers.matchFulfilled, (state, { payload }) => {
            state.allCustomers = payload.customers;
        });
    },
});

export default customerSlice.reducer;
