import React, { ReactNode, useEffect, useState } from 'react';
import cmsService from 'src/services/cms-service';
import Tooltip from 'src/shared/components/tooltip/Tooltip';
import styles from './BannerTooltip.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { PopperProps } from '@mui/material/Popper';

type AboutThisDataProps = {
    pageName: string;
};

const BannerTooltip = ({ pageName }: AboutThisDataProps) => {
    const [cmsData, setCMSData] = useState<{ content: ReactNode } | null>(null);
    const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
    const [shouldPersist, setShouldPersist] = useState<boolean>(false);

    useEffect(() => {
        if (!pageName) {
            return;
        }

        const fetchCmsContent = async () => {
            const slug = `demo-project-banner-for-${pageName.toLowerCase().split(' ').join('-')}`;
            const { content } = (await cmsService.getArticleBySlug(slug)) || {};
            setCMSData({ content });
        };
        fetchCmsContent();
    }, [pageName]);

    if (!cmsData?.content) {
        return null;
    }

    const handleMouseLeave = () => {
        if (shouldPersist) return;
        setTooltipIsOpen(false);
    };

    const handleMouseEnter = () => {
        if (shouldPersist) return;
        setTooltipIsOpen(true);
    };

    const handleMouseClick = () => {
        setShouldPersist(true);
        setTooltipIsOpen(true);
    };

    const handleCloseIconClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShouldPersist(false);
        setTooltipIsOpen(false);
    };

    const aboutThisDataContent = (
        <>
            {shouldPersist && (
                <CloseIcon
                    className={styles.tooltipCloseIcon}
                    style={{ width: '17px', height: '17px' }}
                    onClick={e => handleCloseIconClick(e)}
                />
            )}
            <span className={styles.tooltipContent} dangerouslySetInnerHTML={{ __html: cmsData.content as string }} />
        </>
    );

    const popperSettings: Partial<PopperProps> = {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 25],
                },
            },
        ],
    };
    return (
        <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleMouseClick}>
            <Tooltip
                placement={'bottom-start'}
                content={aboutThisDataContent}
                isOpen={tooltipIsOpen}
                maxWidth={'595px'}
                padding={'35px'}
                popper={popperSettings}
            >
                <span style={{ textDecoration: 'underline' }}>About this data</span>
            </Tooltip>
        </span>
    );
};

export default BannerTooltip;
