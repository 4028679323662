import React from 'react';

import useAuthService from 'src/shared/hooks/useAuthService';

import CreateNewUserForm from './components/create-new-user-form/CreateNewUserForm';
import UserTypeForm from './components/user-type-form/UserTypeForm';

import styles from './UserDetailsForm.module.scss';
import { useAppSelector, useAppDispatch } from 'src/store';
import { getNewProjectUserFormState } from 'src/app/admin/views/user-management/state/userManagementSelector';
import { reset } from 'src/app/admin/views/user-management/state/userManagementSlice';

import SelectExistingUser from './components/select-existing-user/SelectExistingUser';
import { SecondaryButton } from 'src/shared/components/button/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const UserDetails = () => {
    const { isCurrentUserOnboardingManager, isCurrentUserSuperAdmin } = useAuthService();
    const formState = useAppSelector(getNewProjectUserFormState);
    const [shouldShowCreateUserForm, setShouldShowCreateUserForm] = React.useState(!isCurrentUserSuperAdmin);
    const dispatch = useAppDispatch();

    return (
        <div className={styles.userDetailsForm}>
            {(isCurrentUserOnboardingManager || isCurrentUserSuperAdmin) && <UserTypeForm />}

            {!shouldShowCreateUserForm && (
                <>
                    <SelectExistingUser />
                    {!formState.selectedUser && (
                        <>
                            <div>or</div>
                            <SecondaryButton onClick={() => setShouldShowCreateUserForm(true)}>Create new user</SecondaryButton>
                        </>
                    )}
                </>
            )}
            {shouldShowCreateUserForm && (
                <div className={styles.createUserFormWrapper}>
                    <div className={styles.close}>
                        <IconButton
                            onClick={() => {
                                setShouldShowCreateUserForm(false);
                                dispatch(reset());
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <CreateNewUserForm />
                </div>
            )}
        </div>
    );
};

export default UserDetails;
