import capitalize from 'lodash/capitalize';
import apiService from './api-service';
import config from 'src/config';

interface GBIFSpecies {
    species: string;
    scientificName: string;
    canonicalName: string;
}

class GBIFService {
    public async getSpeciesByScientificName(searchText: string): Promise<string[]> {
        try {
            const response = await apiService.fetch(`${config.gbifApiUrl}/species/search?q=${searchText}&rank=SPECIES&limit=10`);

            if (!response.results) {
                return [];
            }

            const results = response.results.map((record: GBIFSpecies) => capitalize(record.species));

            const uniqueSpeciesArray: string[] = Array.from(new Set(results));

            return uniqueSpeciesArray;
        } catch (e) {
            return [];
        }
    }
}

export default new GBIFService();
