import { useGetUserPermissionsQuery } from 'src/app/login/state/api/loginGraphSlice';
import useAuthService from './useAuthService';
import { Access, ResourceAccess, Role, UserPermissions } from '../types/graph/user';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { getCurrentProjectId } from 'src/app/projects/views/project-list/state/projectListSelector';
import { useAppSelector } from 'src/store';
import { Resources } from '../types/graph/resources';
import { UserGroup } from 'src/services/auth-service';

const defaultUserPermissions: UserPermissions = {
    customerLevelPermissions: [],
    projectLevelPermission: [],
    tnc_consent: false,
    is_internal_user: false,
};

const defaultResourceAccess: ResourceAccess = {
    [Access.VIEW]: [],
    [Access.CREATE]: [],
    [Access.UPDATE]: [],
    [Access.DELETE]: [],
};

type HasCustomerLevelPermissionParams = {
    customerId: string;
    to: Access | Access[];
    resource: Resources;
};

type HasProjectLevelPermissionParams = {
    projectId: string;
    to: Access | Access[];
    resource: Resources;
};

type HasPermissionParams = {
    projectId?: string | null;
    customerId?: string | null;
    to: Access | Access[];
    resource: Resources;
};

type HasCustomerLevelRoleParams = {
    customerId: string;
    role: Role | Role[];
};

type HasProjectLevelRoleParams = {
    projectId: string;
    role: Role | Role[];
};

const usePermissions = () => {
    const { isAuthenticated, isCurrentUserSuperAdmin, currentUser } = useAuthService();

    const { currentCustomerId } = useCustomer();
    const currentProjectId = useAppSelector(getCurrentProjectId);

    const { isFetching, isError, currentData } = useGetUserPermissionsQuery(undefined, {
        skip: !isAuthenticated,
    });

    const getUserPermissions = () => {
        if (!isAuthenticated || !currentData || isFetching) {
            return defaultUserPermissions;
        }
        return currentData.myAppPermission || defaultUserPermissions;
    };

    const getPermissionsForAllCustomers = () => {
        return getUserPermissions().customerLevelPermissions;
    };

    const getPermissionsForOneCustomer = (customerId: string) => {
        const allCustomerPermissions = getPermissionsForAllCustomers();
        return allCustomerPermissions.find(entry => entry.customerId === customerId);
    };

    const getRolesForOneCustomer = (customerId: string) => {
        const selectedCustomerPermissions = getPermissionsForOneCustomer(customerId);
        return selectedCustomerPermissions?.roles || [];
    };

    const getPermissionsForAllProjects = () => {
        return getUserPermissions().projectLevelPermission;
    };

    const getPermissionsForOneProject = (projectId: string) => {
        const allProjectPermissions = getPermissionsForAllProjects();
        return allProjectPermissions.find(entry => entry.projectId === projectId);
    };

    const getRolesForOneProject = (projectId: string) => {
        const selectedProjectPermissions = getPermissionsForOneProject(projectId);
        return selectedProjectPermissions?.roles || [];
    };

    const getResourceAccessForOneCustomer = (customerId: string) => {
        const selectedCustomerPermissions = getPermissionsForOneCustomer(customerId);
        return selectedCustomerPermissions?.permissions || defaultResourceAccess;
    };

    const getResourceAccessForOneProject = (projectId: string) => {
        const selectedProjectPermissions = getPermissionsForOneProject(projectId);
        return selectedProjectPermissions?.permissions || defaultResourceAccess;
    };

    const hasCustomerLevelPermission = (params: HasCustomerLevelPermissionParams) => {
        if (currentUser?.groups?.includes(UserGroup.SUPER_ADMIN)) {
            return true;
        }
        const { customerId, resource, to } = params;
        const selectedCustomerResourceAccess = getResourceAccessForOneCustomer(customerId);
        const access = typeof to === 'object' ? to : [to];
        return access.some(access => selectedCustomerResourceAccess[access]?.includes(resource));
    };

    const hasProjectLevelPermission = (params: HasProjectLevelPermissionParams) => {
        if (currentUser?.groups?.includes(UserGroup.SUPER_ADMIN)) {
            return true;
        }
        const { projectId, resource, to } = params;
        const selectedProjectResourceAccess = getResourceAccessForOneProject(projectId);
        const access = typeof to === 'object' ? to : [to];
        return access.some(access => selectedProjectResourceAccess[access]?.includes(resource));
    };

    const hasProjectLevelPermissionToAnyProject = (params: Omit<HasProjectLevelPermissionParams, 'projectId'>) => {
        if (currentUser?.groups?.includes(UserGroup.SUPER_ADMIN)) {
            return true;
        }
        const { resource, to } = params;
        const allProjectResourceAccess = getPermissionsForAllProjects();
        const access = typeof to === 'object' ? to : [to];
        return access.some(access => allProjectResourceAccess.some(project => project.permissions[access]?.includes(resource)));
    };

    const hasCustomerLevelPermissionToAnyCustomer = (params: Omit<HasCustomerLevelPermissionParams, 'customerId'>) => {
        if (currentUser?.groups?.includes(UserGroup.SUPER_ADMIN)) {
            return true;
        }
        const { resource, to } = params;
        const allCustomerResourceAccess = getPermissionsForAllCustomers();
        const access = typeof to === 'object' ? to : [to];
        return access.some(access => allCustomerResourceAccess.some(customer => customer.permissions[access]?.includes(resource)));
    };

    const currentUserBelongsToGroup = (group: UserGroup) => {
        return currentUser?.groups?.includes(group) || false;
    };

    const hasGroupPermission = (params: HasPermissionParams) => {
        const { resource, to } = params;

        const accesses = typeof to === 'object' ? to : [to];

        return [
            // Is super admin user
            currentUserBelongsToGroup(UserGroup.SUPER_ADMIN),
            // Onboarding managers can access all user resources except edit user
            currentUserBelongsToGroup(UserGroup.ONBOARDING_MANAGER) &&
                resource === Resources.USER &&
                accesses.some(access => [Access.VIEW, Access.CREATE, Access.DELETE].includes(access)),
            // Organisational viewers can access all resources in view mode
            currentUserBelongsToGroup(UserGroup.ORGANISATIONAL_VIEWER) && accesses.includes(Access.VIEW),
        ].some(Boolean);
    };

    const hasPermission = (params: HasPermissionParams) => {
        if (hasGroupPermission(params)) {
            return true;
        }

        const { projectId = currentProjectId, customerId = currentCustomerId, resource, to } = params;

        if (!customerId) {
            return false;
        }

        if (currentUser?.groups?.includes(UserGroup.ONBOARDING_MANAGER) && resource === Resources.USER) {
            return true;
        }

        if (
            projectId &&
            hasProjectLevelPermission({
                projectId,
                resource,
                to,
            })
        ) {
            return true;
        }
        return hasCustomerLevelPermission({
            customerId,
            resource,
            to,
        });
    };

    const hasCustomerLevelRole = (params: HasCustomerLevelRoleParams) => {
        if (isCurrentUserSuperAdmin) {
            return true;
        }
        const { customerId = currentCustomerId, role } = params;
        const selectedCustomerRoles = getRolesForOneCustomer(customerId);
        const roles = typeof role === 'object' ? role : [role];
        return selectedCustomerRoles.length ? roles.every(currentRole => selectedCustomerRoles.includes(currentRole)) : false;
    };

    const hasProjectLevelRole = (params: HasProjectLevelRoleParams) => {
        if (isCurrentUserSuperAdmin) {
            return true;
        }
        const { projectId, role } = params;
        const selectedProjectRoles = getRolesForOneProject(projectId);
        const roles = typeof role === 'object' ? role : [role];
        return roles.length ? roles.every(currentRole => selectedProjectRoles.includes(currentRole)) : false;
    };

    return {
        allPermissions: currentData?.myAppPermission,
        isFetchingPermissions: isFetching,
        errorFetchingPermissions: isError,
        getUserPermissions,
        getPermissionsForAllCustomers,
        getPermissionsForAllProjects,
        getRolesForOneCustomer,
        getRolesForOneProject,
        getResourceAccessForOneCustomer,
        getResourceAccessForOneProject,
        hasCustomerLevelPermission,
        hasProjectLevelPermission,
        hasProjectLevelPermissionToAnyProject,
        hasCustomerLevelPermissionToAnyCustomer,
        hasPermission,
        hasCustomerLevelRole,
        hasProjectLevelRole,
        currentUserBelongsToGroup,
        isCurrentUserInternal: getUserPermissions().is_internal_user,
    };
};

export default usePermissions;
