import React, { ReactNode } from 'react';
import classNames from 'classnames';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styles from './Modal.module.scss';
import { createPortal } from 'react-dom';

type Props = {
    onClose?: () => void;
    children: ReactNode;
    classNames?: {
        background?: string;
        body?: string;
    };
};

const Modal = (props: Props) => {
    const preventEventBubbling = (event: React.SyntheticEvent) => {
        event.stopPropagation();
    };

    const backgroundClasses = classNames(styles.background, props.classNames?.background);

    const bodyClasses = classNames(styles.body, props.classNames?.body);

    const modal = (
        <div className={backgroundClasses}>
            <div className={bodyClasses} onClick={preventEventBubbling}>
                {props.onClose && (
                    <div className={styles.close}>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                )}
                <div className={styles.content}>{props.children}</div>
            </div>
        </div>
    );

    return createPortal(modal, document.body);
};

export default Modal;
