import React, { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';
import moment from 'moment';

import { AppName } from 'src/global/globalConfig';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import HabitatAssayLozenges, { HabitatAssayLozengeOption } from 'src/shared/components/lozenges/HabitatAssayLozenges';
import { SelectOption } from 'src/shared/components/select/Select';
import useProject from 'src/shared/hooks/useProject';
import { getDateQuarterSelectOption } from 'src/shared/utils/dateHelpers';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getCurrentTaxonomicCompositionUIState } from '../../state/taxonomic-composition/taxonomicCompositionSelector';
import { TaxonomicCompositionFiltersState, updateFilters } from '../../state/taxonomic-composition/taxonomicCompositionSlice';
import TaxonomicCompositionChart from './components/taxonomic-composition-chart/TaxonomicCompositionChart';
import styles from './TaxonomicComposition.module.scss';

const PAGE_TITLE = 'Taxonomic Composition';

const TaxonomicComposition = () => {
    const {
        currentProjectDetails,
        currentProjectFilters,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        setCurrentProjectHabitatAssayType,
    } = useProject();
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);
    const [habitatAssayTypeOptions, setHabitatAssayTypeOptions] = useState<HabitatAssayLozengeOption[]>([]);
    const [projectDateOptionList, setProjectDateOptionList] = useState<SelectOption[]>();

    const dispatch = useAppDispatch();

    const uiState = useAppSelector(getCurrentTaxonomicCompositionUIState);

    const {
        filters: { fromDate },
    } = uiState;

    const onFiltersChange = (fragment: Partial<TaxonomicCompositionFiltersState>) => {
        dispatch(updateFilters(fragment));
    };

    useEffect(() => {
        if (!currentProjectFilters) {
            return;
        }

        const { habitatAssays, areaOptions } = currentProjectFilters;

        const newAssaysOptions = habitatAssays.map(entry => ({
            habitatName: entry.habitat.habitatName,
            assayName: entry.assay.assayName,
            value: entry.key,
        }));

        setHabitatAssayTypeOptions(newAssaysOptions);

        const newAreaOptions = [...areaOptions];

        if (!currentProjectDetails) {
            return;
        }

        const samples = currentProjectDetails.samples;
        const uniqueSampleDates = uniq(samples.map(entry => moment(entry.sampleCollectedDatetime).startOf('Q').valueOf()))
            .sort()
            .reverse();

        const startDateOptions = uniqueSampleDates.map(entry => getDateQuarterSelectOption(entry)).reverse();
        setProjectDateOptionList(startDateOptions);

        onFiltersChange({
            fromDate: fromDate || startDateOptions[0]?.value,
            areas: newAreaOptions.map(entry => entry.value),
        });
    }, [currentProjectFilters?.habitatAssays, currentProjectFilters?.areaOptions, currentProjectDetails]);

    if (!currentProjectDetails) {
        return null;
    }

    if (!projectDateOptionList) {
        return null;
    }

    const lozenges = (
        <HabitatAssayLozenges
            options={habitatAssayTypeOptions}
            selectedValue={habitatAssayType?.key || ''}
            onChange={setCurrentProjectHabitatAssayType}
            app={AppName.TAXONOMIC_COMPOSITION}
        />
    );
    const mainContent = (
        <div className={styles.taxonomicCompositionChart}>
            <TaxonomicCompositionChart projectDateOptionList={projectDateOptionList} />
        </div>
    );

    return (
        <StandardAppLayout
            breadcrumbs={currentProjectBreadcrumbDetails}
            lozenges={lozenges}
            title={PAGE_TITLE}
            subTitle='A heat tree chart showing species observations across each Taxonomic rank'
            mainContent={mainContent}
            page={AppName.TAXONOMIC_COMPOSITION}
        />
    );
};

export default TaxonomicComposition;
