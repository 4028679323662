import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import {
    CreateProjectUserQueryResult,
    GetCustomerUsersQueryResult,
    getCustomerUsersQuery,
    createProjectUserQuery,
    CheckUsernameAvailableQueryResult,
    checkUsernameAvailableQuery,
    updateProjectUserQuery,
    enableProjectuserQuery,
    disableProjectuserQuery,
    resendInvitationEmailQuery,
    getUserListSummaryQuery,
    GetUserListSummaryResult,
    CreateUserQueryResult,
    createUserQuery,
    GetCustomerAdminUsersQueryResult,
    getCustomerAdminUsersQuery,
    getCustomerUsersForSuperAdminQuery,
    GetCustomerUsersForSuperAdminQueryResult,
    GetUserResult,
    getUserQuery,
    updateUserQuery,
    unassignUserFromOrganisationQuery,
    reassignUserFromOrganisationQuery,
    HasCustomerAdminUserQueryResult,
    hasCustomerAdminUserQuery,
} from './queries/userManagementQuery';
import { UserProjectAccess } from '../userManagementSlice';
import { AssociatedCustomer } from 'src/shared/types';

type CreateProjectUserParams = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    projects: UserProjectAccess[];
    customerId: string;
};

type UpdateProjectUserParams = Omit<CreateProjectUserParams, 'email'> & { tnc_consent: boolean; cognitoId: string; notify: boolean };

type GetCustomerUsersQueryParams = {
    customerId: string;
    projectId?: string;
};

type CheckUsernameAvailableQueryParams = {
    email: string;
};

type UpdateUserStatus = {
    email: string;
    notify: boolean;
};

type CreateUserParams = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    projects: UserProjectAccess[];
    customers: Partial<AssociatedCustomer>[];
    tnc_consent: boolean;
    isNatureMetricsUser: boolean;
};

type UpdateUserParams = Omit<CreateUserParams, 'email' | 'isNatureMetricsUser'> & { cognitoId: string };

type GetCustomerAdminUsersQueryParams = {
    customerId: string;
};

type GetCustomerUsersForSuperAdminQueryParams = {
    customerId: string;
    showOnlyExternalUsers: boolean;
};

type AssignUserToOrganisationParams = {
    id: number;
    customerId: string;
    notify: boolean;
};

const userManagementGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        createUser: builder.mutation<CreateUserQueryResult, CreateUserParams>({
            query: params => ({
                body: createUserQuery,
                variables: { input: params },
            }),
            invalidatesTags: response => ['users', { type: 'user', id: response?.id }],
        }),
        adminUpdateUser: builder.mutation<boolean, UpdateUserParams>({
            query: params => {
                const { cognitoId, ...inputParams } = params;
                return {
                    body: updateUserQuery,
                    variables: { input: inputParams, cognitoId: cognitoId },
                };
            },
            invalidatesTags: ['users'],
        }),

        createProjectUser: builder.mutation<CreateProjectUserQueryResult, CreateProjectUserParams>({
            query: params => ({
                body: createProjectUserQuery,
                variables: params,
            }),
            invalidatesTags: response => ['users', { type: 'user', id: response?.id }],
        }),

        getCustomerUsers: builder.query<GetCustomerUsersQueryResult, GetCustomerUsersQueryParams>({
            query: params => ({
                body: getCustomerUsersQuery,
                variables: params,
            }),
            providesTags: ['users'],
        }),
        checkUsernameAvailable: builder.query<CheckUsernameAvailableQueryResult, CheckUsernameAvailableQueryParams>({
            query: params => ({
                body: checkUsernameAvailableQuery,
                variables: params,
            }),
        }),
        updateProjectUser: builder.mutation<boolean, UpdateProjectUserParams>({
            query: params => ({
                body: updateProjectUserQuery,
                variables: params,
            }),
            invalidatesTags: ['users'],
        }),
        enableUser: builder.mutation<boolean, UpdateUserStatus>({
            query: params => ({
                body: enableProjectuserQuery,
                variables: params,
            }),
            invalidatesTags: ['users'],
        }),
        disableUser: builder.mutation<boolean, UpdateUserStatus>({
            query: params => ({
                body: disableProjectuserQuery,
                variables: params,
            }),
            invalidatesTags: ['users'],
        }),
        resendInvitationEmail: builder.mutation<boolean, { email: string }>({
            query: params => ({
                body: resendInvitationEmailQuery,
                variables: params,
            }),
            invalidatesTags: ['users'],
        }),
        getUserListSummary: builder.query<GetUserListSummaryResult, { projectIds: string[] }>({
            query: params => ({
                body: getUserListSummaryQuery,
                variables: params,
            }),
            providesTags: ['users'],
        }),
        getCustomerAdminUsers: builder.query<GetCustomerAdminUsersQueryResult, GetCustomerAdminUsersQueryParams>({
            query: params => ({
                body: getCustomerAdminUsersQuery,
                variables: params,
            }),
            providesTags: ['users'],
        }),

        getCustomerUsersForSuperAdmin: builder.query<GetCustomerUsersForSuperAdminQueryResult, GetCustomerUsersForSuperAdminQueryParams>({
            query: params => ({
                body: getCustomerUsersForSuperAdminQuery,
                variables: params,
            }),
            providesTags: ['users'],
        }),

        getUser: builder.query<GetUserResult, CheckUsernameAvailableQueryParams>({
            query: params => ({
                body: getUserQuery,
                variables: params,
            }),
            providesTags: response => [{ type: 'user', id: response?.admin.user.id }],
        }),

        unassignUserFromOrganisation: builder.mutation<boolean, AssignUserToOrganisationParams>({
            query: params => ({
                body: unassignUserFromOrganisationQuery,
                variables: params,
            }),
            invalidatesTags: ['users'],
        }),

        reassignUserToOrganisation: builder.mutation<boolean, AssignUserToOrganisationParams>({
            query: params => ({
                body: reassignUserFromOrganisationQuery,
                variables: params,
            }),
            invalidatesTags: ['users'],
        }),

        hasCustomerAdminUser: builder.query<HasCustomerAdminUserQueryResult, GetCustomerAdminUsersQueryParams>({
            query: params => ({
                body: hasCustomerAdminUserQuery,
                variables: params,
            }),
        }),
    }),
});

export const {
    useCreateUserMutation,
    useAdminUpdateUserMutation,
    useCreateProjectUserMutation,
    useGetCustomerUsersQuery,
    useCheckUsernameAvailableQuery,
    useUpdateProjectUserMutation,
    useEnableUserMutation,
    useDisableUserMutation,
    useResendInvitationEmailMutation,
    useGetUserListSummaryQuery,
    useGetCustomerAdminUsersQuery,
    useLazyGetCustomerAdminUsersQuery,
    useGetCustomerUsersForSuperAdminQuery,
    useGetUserQuery,
    useUnassignUserFromOrganisationMutation,
    useReassignUserToOrganisationMutation,
    useHasCustomerAdminUserQuery,
} = userManagementGraphSlice;
