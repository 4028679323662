import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import styles from './InfoMessage.module.scss';
import { InfoOutlined } from '@mui/icons-material';

type InfoMessageProps = {
    message: ReactNode;
    isDismissable?: boolean;
    className?: string;
};

const InfoMessage = (props: InfoMessageProps) => {
    const { message, isDismissable = false } = props;

    const [hasDismissed, setDismissed] = useState(false);

    if (hasDismissed) {
        return null;
    }

    const infoMessageClasses = classNames(styles.infoMessage, props.className);

    return (
        <div className={infoMessageClasses}>
            <InfoOutlined />
            <div className={styles.message} data-testid='message'>
                {message}
            </div>
            {isDismissable && (
                <div className={styles.dismiss} onClick={() => setDismissed(true)} data-testid='dismiss-button'>
                    Dismiss
                </div>
            )}
        </div>
    );
};

export default InfoMessage;
