import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import styles from './StepIndicator.module.scss';
import classNames from 'classnames';

type StepIndicatorProps = {
    currentStep: number;
    steps: string[];
};

const StepIndicator = (props: StepIndicatorProps) => {
    const { currentStep, steps } = props;

    const getStepClassnames = (step: number) => {
        return classNames(styles.step, {
            [styles.stepCompleted]: step <= currentStep,
            [styles.stepActive]: step === currentStep,
        });
    };

    return (
        <div className={styles.stepIndicator}>
            {steps.map((step, index) => {
                const isLastStep = steps.length === index + 1;
                return (
                    <div className={getStepClassnames(index)} key={index}>
                        <span className={styles.stepBullet}>{currentStep > index ? <CheckIcon /> : index + 1}</span>
                        <span className={styles.stepText}>{step}</span>
                        {!isLastStep && <span className={styles.stepConnector}></span>}
                    </div>
                );
            })}
        </div>
    );
};

export default StepIndicator;
