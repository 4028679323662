import React, { ReactNode, useEffect, useState } from 'react';
import { TooltipProps } from '@mui/material';
import startCase from 'lodash/startCase';
import QuestionButton from 'src/shared/components/question-button/QuestionButton';
import cmsService from 'src/services/cms-service';
import Tooltip from '../tooltip/Tooltip';
import styles from './QuestionButtonHelp.module.scss';
import HelpPopup from '../help-popup/HelpPopup';
import CMSTechincalExplainer from '../cms-technical-explainer/CMSTechnicalExplainer';

type CommonQuestionButtonHelpProps = {
    label?: string;
    placement?: TooltipProps['placement'];
    maxWidth?: string | number;
    padding?: string | number;
    showHelpTitle?: boolean;
    detailedExplainerSlug?: string;
};

type QuestionButtonHelpProps =
    | (CommonQuestionButtonHelpProps & {
          type: 'custom';
          helpText: ReactNode;
      })
    | (CommonQuestionButtonHelpProps & {
          type: 'api';
          slug: string;
      });

const QuestionButtonHelp = (props: QuestionButtonHelpProps) => {
    const { placement = 'right', type } = props;
    const [cmsData, setCMSData] = useState<{
        content: ReactNode;
        title: ReactNode;
    } | null>(null);

    const [cmsDetailedData, setCMSDetailedData] = useState<{
        content: ReactNode;
        title: ReactNode;
    } | null>(null);

    const [open, setOpen] = useState<boolean>(false);
    const [shouldShowModal, showModal] = useState<boolean>(false);
    useEffect(() => {
        if (props.type === 'custom') {
            setCMSData({
                content: props.helpText,
                title: props.label,
            });
            return;
        }

        const explainerSlug = props.detailedExplainerSlug || `${props.slug}-te`;
        const fetchDetailedHelpContent = async () => {
            const { content, title } = (await cmsService.getArticleBySlug(explainerSlug)) || {};

            setCMSDetailedData({ content, title: title || props.label });
        };
        fetchDetailedHelpContent();

        const fetchHelpContent = async () => {
            const { content, title } = (await cmsService.getArticleBySlug(props.slug)) || {};

            setCMSData({ content, title: title || props.label });
        };

        fetchHelpContent();
    }, [props]);

    if (!cmsData) {
        return null;
    }

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const content = type === 'custom' ? cmsData.content : <span dangerouslySetInnerHTML={{ __html: cmsData.content as string }} />;

    const contentWithTitle = (
        <>
            {props.showHelpTitle && <div className={styles.title}>{cmsData.title && startCase(cmsData.title.toString())}</div>}
            {content}
            {cmsDetailedData?.content && cmsDetailedData?.title && (
                <HelpPopup
                    popupClassNames={{ body: styles.detailedContentbody }}
                    label={
                        <span className={styles.detailedPopupLabel}>
                            <div>View detailed description</div>
                        </span>
                    }
                    onOpen={() => showModal(true)}
                    onClose={() => {
                        showModal(false);
                        handleTooltipClose();
                    }}
                >
                    <CMSTechincalExplainer title={cmsDetailedData.title} content={cmsDetailedData.content} />
                </HelpPopup>
            )}
        </>
    );

    const popperStyle = {
        style: {
            zIndex: 1000,
        },
    };

    return (
        <div className={styles.label} onMouseEnter={handleTooltipOpen} onMouseLeave={shouldShowModal ? undefined : handleTooltipClose}>
            {props.label && <span className={styles.labelTitle}>{props.label}</span>}
            <Tooltip
                placement={placement}
                content={contentWithTitle}
                isOpen={open}
                maxWidth={props.maxWidth}
                padding={props.padding}
                popper={popperStyle}
            >
                <QuestionButton />
            </Tooltip>
        </div>
    );
};

export default QuestionButtonHelp;
