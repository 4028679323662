import { TaxonomySummary } from 'src/shared/types';
import { getConservationStatusTag } from 'src/shared/helpers/iucnStatusHelper';

export const filterByGRIISList = (data: TaxonomySummary): TaxonomySummary => {
    return data.filter(item => item.conservationStatus.onGRIISList);
};

export const filterByRedListStatus = (data: TaxonomySummary): TaxonomySummary => {
    return data
        .filter(item => {
            const status = item.conservationStatus.iucnRedListStatus;
            return ['CR', 'EN', 'VU', 'NT'].includes(status);
        })
        .map(item => {
            return {
                ...item,
                ...getConservationStatusTag(item.conservationStatus),
            };
        })
        .sort((a, b) => {
            return (a.iucnStatusRank || 0) - (b.iucnStatusRank || 0);
        });
};
