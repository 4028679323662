import React, { useEffect } from 'react';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import EmptyStateIcon from 'src/assets/images/user/img-emptystate.png';
import NoData from 'src/shared/components/no-data/NoData';
import { PrimaryButton } from 'src/shared/components/button/Button';
import { useGetCustomerUsersQuery, useGetCustomerUsersForSuperAdminQuery } from './state/api/userManagementGraphSlice';
import UserManagementList from './components/user-management-list/UserManagementList';
import UserManagementFilters from './components/user-management-filters/UserManagementFilters';
import styles from './UserManagement.module.scss';
import Loader from 'src/shared/components/loader/Loader';
import { useAppDispatch } from 'src/store';
import { updateGeneralUiState } from './state/userManagementSlice';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { Access, Resources, AdminUserType } from 'src/shared/types';
import useAuthService from 'src/shared/hooks/useAuthService';
import CustomerDetails from './components/customer-details/CustomerDetails';
import usePermissions from 'src/shared/hooks/usePermissions';
import { useGetCustomerAdminUsersQuery } from 'src/app/admin/views/user-management/state/api/userManagementGraphSlice';
import UserManagementModals from './components/user-management-list/UserManagementModals';

const pageText = {
    title: {
        [AdminUserType.ADMIN]: 'Admin User Management',
        [AdminUserType.SUPER_ADMIN]: 'Super Admin User Management',
    },
    subtitle: {
        [AdminUserType.ADMIN]: "Assign projects and permissions for your organisation's users",
        [AdminUserType.SUPER_ADMIN]: 'Add users to this organisation and assign their projects & permissions',
    },
    noDataSubTitle: {
        [AdminUserType.ADMIN]:
            "Please create your first user account to begin assigning projects and permissions for your organisation's users.",
        [AdminUserType.SUPER_ADMIN]: 'Begin by adding the customer admin and/or a project admin for this organisation',
    },
};

const UserManagement = () => {
    const dispatch = useAppDispatch();
    const { currentCustomerId, currentCustomerName } = useCustomer();
    const { isCurrentUserOnboardingManager, isCurrentUserSuperAdmin } = useAuthService();
    const { hasPermission, hasProjectLevelPermissionToAnyProject } = usePermissions();
    const canCreateNewUser =
        hasPermission({
            to: Access.CREATE,
            resource: Resources.USER,
        }) ||
        hasProjectLevelPermissionToAnyProject({
            to: Access.CREATE,
            resource: Resources.USER,
        });

    const { isLoading: isFetchingUserForAdmin, currentData: currentDataForAdmin } = useGetCustomerUsersQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: isCurrentUserOnboardingManager || isCurrentUserSuperAdmin,
        }
    );

    const { isLoading: isFetchingForCustomerAdmins, currentData: currentDataForCustomerAdmins } = useGetCustomerAdminUsersQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: !(isCurrentUserOnboardingManager || isCurrentUserSuperAdmin),
        }
    );

    const { isLoading: isFetchingUserForSuperAdmin, currentData: currentDataForSuperAdmin } = useGetCustomerUsersForSuperAdminQuery(
        {
            customerId: currentCustomerId,
            showOnlyExternalUsers: isCurrentUserOnboardingManager,
        },
        {
            skip: !(isCurrentUserOnboardingManager || isCurrentUserSuperAdmin),
        }
    );

    useEffect(() => {
        if (!currentDataForCustomerAdmins) {
            return;
        }
        const hasCustomerAdminInUsers = Boolean(currentDataForCustomerAdmins?.customerAdmins?.length);
        dispatch(updateGeneralUiState({ hasCustomerAdminInUsers }));
    }, [currentDataForCustomerAdmins, isFetchingForCustomerAdmins]);

    const isFetching = isFetchingUserForAdmin || isFetchingUserForSuperAdmin;
    if (isFetching || (!currentDataForAdmin && !currentDataForSuperAdmin)) {
        return <Loader />;
    }

    const users = [...(currentDataForSuperAdmin?.admin.users || []), ...(currentDataForAdmin?.users || [])].filter(Boolean);

    const sectionType = isCurrentUserSuperAdmin || isCurrentUserOnboardingManager ? AdminUserType.SUPER_ADMIN : AdminUserType.ADMIN;

    const contentWithoutData = (
        <>
            <NoData
                image={EmptyStateIcon}
                text='No user accounts'
                className={styles.emptyStateMessage}
                subText={pageText.noDataSubTitle[sectionType]}
                height='auto'
            />
            {canCreateNewUser && (
                <div className={styles.buttonBar}>
                    <PrimaryButton
                        className={styles.createUserButton}
                        onClick={() => dispatch(updateGeneralUiState({ shouldShowNewUserModal: true }))}
                    >
                        Add user <span>+</span>
                    </PrimaryButton>
                    <UserManagementModals />
                </div>
            )}
        </>
    );

    const contentWithData = (
        <>
            <UserManagementFilters />
            <UserManagementList />
            <UserManagementModals />
        </>
    );

    const mainContent = <div className={styles.emptyState}>{users.length ? contentWithData : contentWithoutData}</div>;

    const customerDetails = isCurrentUserSuperAdmin && currentCustomerName ? <CustomerDetails customerName={currentCustomerName} /> : '';

    return (
        <StandardAppLayout
            mainContent={mainContent}
            title={pageText.title[sectionType]}
            subTitle={pageText.subtitle[sectionType]}
            lozenges={customerDetails}
        />
    );
};

export default UserManagement;
