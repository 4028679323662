import React, { useEffect, useRef } from 'react';

import styles from './BoxPlot.module.scss';

import * as d3 from 'd3';
import { StatisticalComparisonBoxData } from 'src/app/insights/state/api/queries/statisticalComparisonQuery';

const SIZE = 300;
const WIDTH = 700;
const BOX_WIDTH = 70;
const POINT_RADIUS = 5;

type BoxPlotProps = {
    data: StatisticalComparisonBoxData[];
    variable1Label: string;
    variable2Label: string;
    metricName: string;
    shouldShowBoxPlots?: boolean;
    shouldShowSamplePoints?: boolean;
    colours: string[];
};

const BoxPlot = (props: BoxPlotProps) => {
    const {
        data,
        variable1Label,
        variable2Label,
        shouldShowBoxPlots = true,
        shouldShowSamplePoints = true,
        metricName,
        colours = ['#A71B1B', '#E3A52D'],
    } = props;

    const elementRef = useRef<SVGSVGElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const domainMax = getDomainMax(data);

    useEffect(() => {
        if (!elementRef.current || !containerRef.current) {
            return;
        }
        const svgElement = d3.select(elementRef.current);
        const containerElement = d3.select(containerRef.current);

        svgElement.selectAll('*').remove();
        containerElement.selectAll(`.${styles.boxTooltip}`).remove();

        const xScale = d3.scaleLinear().domain([0, WIDTH]).range([30, WIDTH]);
        const xAxisGenerator = d3.axisBottom(xScale).ticks(0);

        const yScale = d3
            .scaleLinear()
            .domain([domainMax + domainMax / 5, 0])
            .range([30, SIZE]);

        const yAxisGenerator = d3.axisLeft(yScale).ticks(5);

        const xAxisPlot = svgElement
            .append('g')
            .call(xAxisGenerator)
            .attr('transform', `translate(0,${SIZE})`)
            .style('z-index', `1`)
            .attr('stroke-width', '0');
        const yAxisPlot = svgElement.append('g').call(yAxisGenerator).attr('transform', 'translate(30)').attr('stroke-dasharray', '2');
        // Y-axis styles
        yAxisPlot.selectAll('path.domain').style('stroke', 'none');

        // Add the x-axis lines/ticks
        xAxisPlot
            .selectAll('.tick line')
            .attr('stroke', 'silver')
            .attr('y1', -(SIZE - 30))
            .attr('y2', 0);

        // Add the y-axis lines/ticks
        yAxisPlot
            .selectAll('.tick line ')
            .attr('stroke', '#E6E6E6')
            .attr('stroke-dasharray', '0')
            .attr('x1', 1)
            .attr('x2', WIDTH - 30);

        data.forEach((entry, index) => {
            const xValue = 200 * (index + 1);

            const habitatColor = colours[index];

            const drawProps = {
                index,
                svgElement,
                xScale,
                yScale,
                xValue,
                habitatColor,
                entry,
                domainMax,
                shouldShowBoxPlots,
                containerElement,
                metricName,
            };

            // drawReferenceLines(drawProps);
            drawMinMaxLines(drawProps);
            if (shouldShowBoxPlots) {
                drawCenterBox(drawProps);
            }
            if (shouldShowSamplePoints) {
                drawPoints(drawProps);
            }
        });
    }, [data]);

    return (
        <div className={styles.boxPlot} ref={containerRef}>
            <svg ref={elementRef} height={SIZE + 20} width={WIDTH + 20}></svg>
            <div className={styles.legends}>
                <span className={styles.legend}>{variable1Label}</span>
                <span className={styles.legend}>{variable2Label}</span>
            </div>
        </div>
    );
};

type DrawProps = {
    index: number;
    metricName: string;
    xScale: d3.ScaleLinear<number, number, never>;
    yScale: d3.ScaleLinear<number, number, never>;
    xValue: number;
    entry: StatisticalComparisonBoxData;
    habitatColor: string;
    domainMax: number;
    shouldShowBoxPlots: boolean;
    containerElement: d3.Selection<HTMLDivElement, unknown, null, undefined>;
    svgElement: d3.Selection<SVGSVGElement, unknown, null, undefined>;
};

const drawMinMaxLines = (props: DrawProps) => {
    const { xScale, yScale, xValue, entry, svgElement, habitatColor, shouldShowBoxPlots, containerElement } = props;

    // Draw the minimum value line
    const path = d3.path();

    // Median
    path.moveTo(xScale(xValue - BOX_WIDTH / 2), yScale(entry.median));

    path.lineTo(xScale(xValue), yScale(entry.median));

    path.lineTo(xScale(xValue + BOX_WIDTH / 2), yScale(entry.median));

    if (shouldShowBoxPlots) {
        // Minimum
        path.moveTo(xScale(xValue - BOX_WIDTH / 4), yScale(entry['q0.025']));

        path.lineTo(xScale(xValue), yScale(entry['q0.025']));

        path.lineTo(xScale(xValue + BOX_WIDTH / 4), yScale(entry['q0.025']));

        // Maximum
        path.moveTo(xScale(xValue - BOX_WIDTH / 4), yScale(entry['q0.975']));

        path.lineTo(xScale(xValue), yScale(entry['q0.975']));

        path.lineTo(xScale(xValue + BOX_WIDTH / 4), yScale(entry['q0.975']));

        // Backbone
        path.moveTo(xScale(xValue), yScale(entry['q0.025']));
        path.lineTo(xScale(xValue), yScale(entry['q0.25']));
        path.moveTo(xScale(xValue), yScale(entry['q0.75']));
        path.lineTo(xScale(xValue), yScale(entry['q0.975']));
    }

    const pathElement = svgElement
        .append('path')
        .attr('d', path.toString())
        .attr('stroke-linecap', 'round')
        .style('fill', 'none')
        .style('stroke', habitatColor)
        .style('stroke-width', 2);

    if (!shouldShowBoxPlots) {
        const tooltipContent = `<div class="${styles.boxTooltipTitle}">${entry.variable.value}</div>
                <div class="${styles.boxTooltipRow}"><span style="color:${habitatColor}">${entry.median.toFixed(
            2
        )}</span><span>Median</span></div>
            `;

        // Include the tooltip
        const tooltip = containerElement
            .append('div')
            .attr('class', styles.boxTooltip)
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .html(tooltipContent);

        pathElement
            .on('mouseover', event => {
                const elementRect = event.target.getBBox();
                const { height = 0, width = 0 } = tooltip.node()?.getBoundingClientRect() || {};
                const tooltipLeft = elementRect.x - width - 9 + 'px';
                const tooltipTop = elementRect.y - height / 2 + elementRect.height / 2 + 'px';

                return tooltip.style('left', tooltipLeft).style('top', tooltipTop).style('visibility', 'visible');
            })
            .on('mouseout', () => tooltip.style('visibility', 'hidden'));
    }
};

const drawCenterBox = (props: DrawProps) => {
    const { xScale, yScale, xValue, entry, svgElement, habitatColor, containerElement, index } = props;

    const box = svgElement
        .append('rect')
        .attr('id', `box-${index}`)
        .attr('x', xScale(xValue - BOX_WIDTH / 2))
        .attr('y', yScale(entry['q0.75']))
        .attr('stroke-linejoin', 'round')
        .attr('width', BOX_WIDTH - 3)
        .attr('height', yScale(entry['q0.25']) - yScale(entry['q0.75']))
        .style('fill', habitatColor)
        .style('stroke', habitatColor)
        .style('stroke-width', 2)
        .style('fill-opacity', '0.4');

    const tooltipContent = `<div class="${styles.boxTooltipTitle}">${entry.variable.value}</div>
        <div class="${styles.boxTooltipRow}"><span style="color:${habitatColor}">${entry['q0.975'].toFixed(
        2
    )}</span><span>Maximum</span></div>
        <div class="${styles.boxTooltipRow}"><span style="color:${habitatColor}">${entry['q0.75'].toFixed(
        2
    )}</span><span>Upper quartile</span></div>
        <div class="${styles.boxTooltipRow}"><span style="color:${habitatColor}">${entry['median'].toFixed(
        2
    )}</span><span>Median</span></div>
        <div class="${styles.boxTooltipRow}"><span style="color:${habitatColor}">${entry['q0.25'].toFixed(
        2
    )}</span><span>Lower quartile</span></div>
        <div class="${styles.boxTooltipRow}"><span style="color:${habitatColor}">${entry['q0.025'].toFixed(
        2
    )}</span><span>Minimum</span></div>
    `;

    // Include the tooltip
    const tooltip = containerElement
        .append('div')
        .attr('class', styles.boxTooltip)
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .html(tooltipContent);

    box.on('mouseover', event => {
        const elementRect = event.target.getBBox();
        const { height = 0, width = 0 } = tooltip.node()?.getBoundingClientRect() || {};
        const tooltipLeft = elementRect.x - width - 9 + 'px';
        const tooltipTop = elementRect.y - height / 2 + elementRect.height / 2 + 'px';

        return tooltip.style('left', tooltipLeft).style('top', tooltipTop).style('visibility', 'visible');
    }).on('mouseout', () => tooltip.style('visibility', 'hidden'));
};

const drawPoints = (props: DrawProps) => {
    const { xScale, yScale, xValue, entry, svgElement, habitatColor, containerElement, metricName } = props;

    entry.valuesY?.forEach(y => {
        const point = svgElement
            .append('circle')
            .style('fill', habitatColor)
            .style('fill-opacity', 0.5)
            .attr('r', POINT_RADIUS)
            .attr('cx', xScale(xValue))
            .attr('cy', yScale(y.value));

        const tooltipContent = `<div>
            <div class="${styles.pointTooltipSampleId}">${y.sampleId}</div>
            <div class="${styles.pointTooltipValue}">${y.value.toFixed(2)}</div>
            <div class="${styles.pointTooltipMetric}">${metricName}</div>
        </div>`;

        // Include the tooltip
        const tooltip = containerElement
            .append('div')
            .attr('class', styles.pointTooltip)
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .html(tooltipContent);

        point
            .on('mouseover', event => {
                const elementRect = event.target.getBBox();
                const { height = 0 } = tooltip.node()?.getBoundingClientRect() || {};
                const tooltipLeft = elementRect.x + POINT_RADIUS + 14 + 'px';
                const tooltipTop = elementRect.y - height / 2 + elementRect.height / 2 + 'px';

                return tooltip.style('left', tooltipLeft).style('top', tooltipTop).style('visibility', 'visible');
            })
            .on('mouseout', () => tooltip.style('visibility', 'hidden'));
    });
};

const getDomainMax = (data: StatisticalComparisonBoxData[]) => {
    const values: number[] = [];

    data.forEach(entry => {
        const valuesY = entry.valuesY.map(v => v.value);
        values.push(Math.max(...valuesY));
        values.push(entry['q0.975']);
    });

    return Number(Math.ceil(Math.max(...values.map(Number))));
};
export default BoxPlot;
