import React from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import styles from './Tabs.module.scss';
import { ReactNode } from 'react';

export type Tab = {
    label: ReactNode;
    id: string;
    isDisabled?: boolean;
};

export type TabsProps = {
    tabs: Tab[];
    classNames?: {
        default?: string;
        disabled?: string;
        selected?: string;
        tabsContainer?: string;
    };
    selectedTab: string | null;
    onTabChange: (selectedTab: string) => void;
};

export const Tabs = (props: TabsProps) => {
    const getTabClassNames = (tab: Tab) => {
        const defaultClassNames = classNames(styles.tab, props.classNames?.default);

        const disabledClassNames = classNames(styles.disabled, props.classNames?.disabled);

        const selectedClassNames = classNames(styles.selected, props.classNames?.selected);

        return classNames(defaultClassNames, {
            [disabledClassNames]: tab.isDisabled,
            [selectedClassNames]: !tab.isDisabled && tab.id === props.selectedTab,
        });
    };

    const tabsContainerClassName = classNames(styles.tabsContainer, props.classNames?.tabsContainer);

    const onTabSelect = (tabTitle: string) => {
        props.onTabChange(tabTitle);
    };

    return (
        <div className={tabsContainerClassName} data-testid='tabs-container'>
            {Object.values(props.tabs).map((tab: Tab) => (
                <span
                    className={getTabClassNames(tab)}
                    key={tab.id}
                    data-testid={`tab-${tab.id}`}
                    onClick={tab.isDisabled ? noop : () => onTabSelect(tab.id)}
                >
                    {tab.label}
                </span>
            ))}
        </div>
    );
};

export default Tabs;
