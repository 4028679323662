import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import { HabitatInsightsUploadQueryResult, habitatInsightsUploadQuery } from './queries/habitatInsightsUploadQuery';
import { HabitatInsightsGeoJson } from 'src/shared/types';

type HabitatInsightsUploadQueryParams = {
    projectId: string;
    customerId: string;
    sites: HabitatInsightsGeoJson[];
};

const habitatInsightsUploadGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        habitatInsightsUpload: builder.mutation<HabitatInsightsUploadQueryResult, HabitatInsightsUploadQueryParams>({
            query: ({ projectId, customerId, sites }) => ({
                body: habitatInsightsUploadQuery,
                variables: { input: { projectId, customerId, sites: JSON.parse(JSON.stringify(sites)) } },
            }),
        }),
    }),
});

export const { useHabitatInsightsUploadMutation } = habitatInsightsUploadGraphSlice;
