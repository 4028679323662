import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Search from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import UserRoleIcon from '@mui/icons-material/GroupOutlined';

import { Access, Role, Resources, ProgressStatus } from 'src/shared/types';
import { useGetUserListSummaryQuery } from 'src/app/admin/views/user-management/state/api/userManagementGraphSlice';
import { ProjectsQueryResult } from 'src/app/projects/views/project-list/state/api/queries/projectsListQuery';
import Autocomplete from 'src/shared/components/autocomplete/Autocomplete';
import Button, { TertiaryButton } from 'src/shared/components/button/Button';
import Checkbox from 'src/shared/components/checkbox/Checkbox';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import { getRoleLabel } from 'src/app/admin/views/user-management/components/userManagementHelpers';
import styles from './AssignProjects.module.scss';
import { AssignProjectProps } from './AssignProjects';
import WarningSectionElement from './components/WarningSectionElement';
import useBoolean from 'src/shared/hooks/useBoolean';
import usePermissions from 'src/shared/hooks/usePermissions';
import useCustomer from 'src/app/customers/hooks/useCustomer';

type AssignProjectCardProps = AssignProjectProps & {
    index: number;
    projectsData?: ProjectsQueryResult;
    isInternalUser: boolean;
};

type CountByRole = {
    name: string;
    roleCount: number;
    checked: boolean;
    limitation: {
        max: number;
        softLimit: number;
    };
};

const AssignProjectCard = (props: AssignProjectCardProps) => {
    const [errorMessage, setErrorMessage] = useState('');
    const { currentCustomerId } = useCustomer();
    const { hasProjectLevelRole, hasCustomerLevelRole, hasPermission } = usePermissions();
    const { index, formState, onFormChange, formMode = Access.UPDATE, disabled = false, projectsData, isInternalUser } = props;
    const [userCountSummary, setUserCountSummary] = useState<{
        countByRoles: CountByRole[] | [];
        remark: string | undefined;
    }>({
        countByRoles: [],
        remark: undefined,
    });
    const [isProjectAlreadyAssigned, setProjectAlreadyAssigned] = useBoolean(false);

    const [warningSection, setWarningSection] = useState<JSX.Element[] | null>(null);
    const showWarning = formMode === Access.VIEW && disabled ? false : true;
    const viewOnly = formMode === Access.VIEW && !disabled;

    const { projects } = formState;
    const projectIdsForCurrentCustomer = projectsData?.customer.projects.map(project => project.projectId) || [];
    const filteredProjects = projects.filter(project => projectIdsForCurrentCustomer.includes(project.projectId));
    const { status: userStatus } = formState;

    const [projectNameSearchText, setProjectNameSearchText] = useState('');

    const selectedProjectData = projectsData?.customer.projects.find(entry => entry.projectId === projects[index]?.projectId);
    const isCurrentUserProjectAdmin = hasProjectLevelRole({
        projectId: selectedProjectData?.projectId || '',
        role: Role.PROJECT_ADMIN,
    });

    const isCurrentUserCustomerAdmin = hasCustomerLevelRole({
        customerId: currentCustomerId,
        role: Role.CUSTOMER_ADMIN,
    });
    const [selectedProjectName, setSelectedProjectName] = useState<string | undefined>(selectedProjectData?.projectName);

    useEffect(() => {
        if (selectedProjectData) {
            setSelectedProjectName(selectedProjectData.projectName);
        }
    }, [selectedProjectData]);

    const { isFetching: isUserCountSummaryFetching, currentData: userCountSummaryData } = useGetUserListSummaryQuery(
        {
            projectIds: [selectedProjectData?.projectId || ''],
        },
        {
            skip: !selectedProjectData?.projectId,
            refetchOnMountOrArgChange: true,
        }
    );

    const setUserCountSummaryData = () => {
        if (userCountSummaryData) {
            const { userCountSummary } = userCountSummaryData;
            const userCountSummaryByRoles = userCountSummary[0].countByRoles.map((entry: any) => {
                let checked = false;
                if ([Access.UPDATE, Access.CREATE, Access.VIEW].includes(formMode)) {
                    projects
                        .find(project => project.projectId === userCountSummary[0].projectId)
                        ?.roles?.map((role: string) => {
                            if (role === entry.name) {
                                checked = true;
                            }
                        });
                }
                return {
                    ...entry,
                    checked: checked,
                };
            });
            setUserCountSummary({
                remark: userCountSummary[0].creationRemark,
                countByRoles: [...userCountSummaryByRoles],
            });
            onChangeUserLimitRequest(userCountSummary[0].creationRemark || '');
        }
    };

    useEffect(() => {
        if (!isUserCountSummaryFetching) {
            if (userCountSummaryData) {
                setUserCountSummaryData();
            }
        }
    }, [isUserCountSummaryFetching, userCountSummaryData]);

    useEffect(() => {
        setUserCountSummaryData();
    }, []);

    useEffect(() => {
        setProjectData(selectedProjectData?.projectId || '');
        if (formMode !== Access.VIEW) {
            setWarning();
        }
    }, [userCountSummary]);

    const onProjectChange = (projectName: string | null | undefined) => {
        if (!projectName) {
            return;
        }

        const selectedProjectData = projectsData?.customer.projects.find(entry => entry.projectName === projectName);
        if (!selectedProjectData) {
            return;
        }

        const isAlreadyAssigned = projects
            .filter((_, i) => i !== index)
            .some((item: { projectId: string }) => item.projectId === selectedProjectData.projectId);

        if (isAlreadyAssigned) {
            setErrorMessage('Selected project already assigned to user');
            setSelectedProjectName(selectedProjectData.projectName);
            setProjectAlreadyAssigned.on();
        } else {
            setErrorMessage('');
            setSelectedProjectName(selectedProjectData.projectName);
            setProjectData(selectedProjectData.projectId);
            setProjectAlreadyAssigned.off();
        }
    };

    const setProjectData = (projectId: string) => {
        if (!projectId) {
            return;
        }

        const roles = userCountSummary.countByRoles.filter(entry => entry.checked === true).map(role => role.name);
        const newProjects = [...projects];
        newProjects[index] = {
            projectId: projectId,
            roles: roles && roles.length ? roles : [],
            remark: userCountSummary.remark || '',
        };

        const hasRoles = roles && roles.length > 0;

        const disableSubmit =
            userCountSummary.countByRoles.some(entry => Boolean(entry.roleCount && entry.roleCount > entry.limitation.max)) || !hasRoles;

        onFormChange({
            projects: newProjects,
            disableSubmit: disableSubmit,
        });
    };

    const onUnassign = () => {
        const newProjects = [...projects];
        newProjects.splice(index, 1);

        onFormChange({
            projects: newProjects,
        });
    };

    const onChangeUserLimitRequest = (value: string) => {
        const newProjects = [...projects];
        newProjects[index] = {
            ...newProjects[index],
            remark: value,
        };

        setUserCountSummary(prevState => ({
            ...prevState,
            remark: value,
        }));
        const disableSubmit = formMode !== Access.CREATE && (!userCountSummary.remark || userCountSummary.remark === value) ? true : false;
        onFormChange({
            projects: newProjects,
            disableSubmit: disableSubmit,
        });
    };

    const handleCheckboxChange = (value: boolean, role: string) => {
        const updatedRoles = userCountSummary.countByRoles.map(entry => {
            if (entry.name === role) {
                value === false ? entry.roleCount-- : entry.roleCount++;
                return { ...entry, checked: value };
            }
            return entry;
        });
        setUserCountSummary(prevState => ({
            ...prevState,
            countByRoles: updatedRoles,
        }));
    };

    const getSeverity = (entry: CountByRole): string | null => {
        const { softLimit, max } = entry.limitation;
        if (entry.roleCount > max) {
            return 'error';
        }

        if (entry.roleCount === max) {
            return 'warning';
        }
        if (softLimit && entry.roleCount > softLimit) {
            return 'info';
        }

        return null;
    };

    const setWarning = () => {
        const warningSection: JSX.Element[] = [];
        userCountSummary.countByRoles.forEach(entry => {
            const { softLimit, max } = entry.limitation;
            const roleLabel = getRoleLabel(entry.name);

            // Don't show Project Admin limit warning for Project Admins
            if (entry.name === Role.PROJECT_ADMIN && isCurrentUserProjectAdmin) {
                return;
            }

            if (softLimit && entry.roleCount > softLimit) {
                warningSection?.push(
                    <WarningSectionElement
                        title={`Free user count of ${roleLabel.toLowerCase()}s exceeded`}
                        count={softLimit}
                        role={roleLabel}
                        contentSlug='project-user-count-warning'
                        severity='info'
                        onChangeUserLimitRequest={onChangeUserLimitRequest}
                        icon={<InfoOutlined />}
                        value={userCountSummary.remark}
                    />
                );
            }

            if (entry.roleCount === max) {
                warningSection?.push(
                    <WarningSectionElement
                        title={`Maximum number of ${roleLabel.toLowerCase()}s created  `}
                        count={max}
                        role={roleLabel}
                        contentSlug='project-user-count-limit-reached'
                        severity='warning'
                        icon={<InfoIcon />}
                    />
                );
            }

            if (entry.roleCount > max) {
                onFormChange({
                    disableSubmit: true,
                });
                warningSection?.push(
                    <WarningSectionElement
                        title={`${roleLabel} user limit exceeded`}
                        count={max}
                        role={roleLabel}
                        contentSlug='project-user-count-limit-exceeded'
                        severity='error'
                        icon={<WarningIcon />}
                    />
                );
            }

            return entry;
        });

        setWarningSection(warningSection);
    };

    const dropdownProjects = projectsData?.customer.projects || [];

    const projectNameOptions =
        dropdownProjects
            .filter(project => {
                // Check if the current user can assign a user to this project
                const canAssignToThisProject = hasPermission({
                    projectId: project.projectId,
                    to: Access.CREATE,
                    resource: Resources.USER,
                });
                // Project admin can assign users to project
                if (!canAssignToThisProject) {
                    return false;
                }
                return [project.status, project.survey?.status].includes(ProgressStatus.COMPLETED);
            })
            .map(entry => entry.projectName) || [];

    const projectCardClassNames = classNames(styles.assignProjectCard, {
        [styles.assignProjectCardDisabled]: disabled,
    });

    const titleClassNames = classNames(
        styles.title,
        {
            [styles.titleDisabled]: disabled,
        },
        {
            [styles.titleViewOnly]: viewOnly,
        }
    );

    const roleTitleClassNames = classNames(styles.roleTitle, {
        [styles.roleTitleDisabled]: disabled,
    });

    const showShowCloseButton = isProjectAlreadyAssigned || (selectedProjectName === undefined && filteredProjects.length > 1);

    return (
        <>
            <div className={projectCardClassNames}>
                <div style={{ position: 'relative' }}>
                    <span className={titleClassNames}>Project</span>
                    {showShowCloseButton && (
                        <Button className={classNames(styles.unassignButton, styles.closeButton)} onClick={onUnassign}>
                            <CancelIcon />
                        </Button>
                    )}
                </div>
                {[Access.UPDATE, Access.CREATE].includes(formMode) ? (
                    <Autocomplete
                        options={['', ...projectNameOptions] || []}
                        placeholder='Type project name'
                        prefixIcon={<Search />}
                        onChange={onProjectChange}
                        onInputChange={setProjectNameSearchText}
                        inputValue={projectNameSearchText}
                        value={selectedProjectName || ''}
                        disabled={disabled}
                    />
                ) : (
                    <div className={styles.projectName}>{selectedProjectName}</div>
                )}

                {userCountSummary.countByRoles.length > 0 && (
                    <>
                        <div className={roleTitleClassNames}>Role(s)</div>
                        <div className={styles.roleContainer}>
                            {userCountSummary.countByRoles.map((entry, index) => {
                                // If a user is both project admin and customer admin, they can create / update project admins
                                if (!isCurrentUserCustomerAdmin) {
                                    // Project admin cannot create project admins
                                    if (selectedProjectData && entry.name === Role.PROJECT_ADMIN && isCurrentUserProjectAdmin) {
                                        return null;
                                    }
                                }

                                // An internal user cannot be an external project admin
                                if (isInternalUser && entry.name === Role.PROJECT_ADMIN) {
                                    return null;
                                }
                                const severity = getSeverity(entry);
                                const roleItemClassNames = classNames(styles.roleItem, severity && styles[`roleItem${severity}`]);
                                const textClass = classNames(
                                    styles.text,
                                    severity && styles[`text${severity}`],
                                    disabled && styles.textDisabled
                                );
                                const iconContainerClassNames = classNames(styles.iconContainer, disabled && styles.iconContainerDisabled);
                                const hasReachedLimit = entry.limitation.max <= entry.roleCount;
                                const isAlreadyAProjectAdmin = entry.name === Role.PROJECT_ADMIN && entry.checked;

                                const isCheckboxDisabled = [
                                    !isAlreadyAProjectAdmin && hasReachedLimit,
                                    isAlreadyAProjectAdmin && hasReachedLimit && !isCurrentUserCustomerAdmin,
                                    disabled,
                                    formMode === Access.VIEW,
                                ].some(Boolean);
                                const labelClassNames =
                                    isCheckboxDisabled && !viewOnly ? styles.roleItemLabelDisabled : styles.roleItemLabel;
                                return (
                                    <div className={roleItemClassNames} key={index}>
                                        <div className={styles.roles}>
                                            <div className={styles.role}>
                                                <Checkbox
                                                    label={getRoleLabel(entry.name)}
                                                    checked={entry.checked}
                                                    onChange={event => handleCheckboxChange(event, entry.name)}
                                                    disabled={isCheckboxDisabled}
                                                    labelClassName={labelClassNames}
                                                    checkboxClassName={styles.roleCheckbox}
                                                />
                                            </div>
                                            {userStatus !== 'Inactive' && (
                                                <div className={iconContainerClassNames}>
                                                    <UserRoleIcon className={styles.icon} />
                                                    <span className={textClass}>{entry.roleCount} Users</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
                {warningSection &&
                    showWarning &&
                    warningSection.map((entry, index) => (
                        <div className={styles.warningSection} key={index}>
                            {entry}
                        </div>
                    ))}

                {selectedProjectName !== undefined && !errorMessage && [Access.UPDATE, Access.CREATE].includes(formMode) && (
                    <div className={styles.unassignContainer}>
                        <TertiaryButton className={styles.unassignButton} onClick={onUnassign} disabled={projects.length < 2}>
                            Unassign from project
                        </TertiaryButton>
                    </div>
                )}
            </div>
            <div>{errorMessage && <ErrorMessage message={errorMessage} />}</div>
        </>
    );
};

export default AssignProjectCard;
