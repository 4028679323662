import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import { statisticalComparisonQuery, StatisticalComparisonQueryResult } from './queries/statisticalComparisonQuery';
import { ecologicalTrendsQuery, EcologicalTrendsQueryResult } from './queries/ecologicalTrendsQuery';
import { taxonomicComparisonQuery, TaxonomicComparisonQueryResult } from './queries/taxonomicComparisonQuery';
import {
    communityComparisonChartDataQuery,
    CommunityComparisonChartDataQueryResult,
    communityComparisonSamplesQuery,
    CommunityComparisonSamplesQueryResult,
} from './queries/communityComparisonQuery';
import { communityComparisonMarkerQuery, communityComparisonMarkersQueryResult } from './queries/communityComparisonMarkerQuery';
import { sampleComparisonQuery, SampleComparisonQueryResult } from './queries/sampleComparisonQuery';
import { SampleFilter } from 'src/shared/types';
import {
    habitatInsightsEcologicalTrendsQuery,
    HabitatInsightsEcologicalTrendsQueryResult,
} from './queries/habitatInsightsEcologicalTrendsQuery';

type GenericQueryParems = {
    projectId: string;
    habitatAssay: string;
    fromDate?: string;
    toDate?: string;
};

type InsightsQueryParams = {
    projectId: string;
    chartId: string;
    habitatAssay: string;
};

type TaxonomicComparisonQueryParams = {
    projectId: string;
    samples: SampleFilter;
};

type CommunityComparisonSamplesQueryParams = {
    projectId: string;
    samples: SampleFilter;
};

type CommunityComparisonMarkerQueryParams = {
    projectId: string;
    sampleId: string;
};

type SampleComparisonQueryParams = {
    projectId: string;
    samples: SampleFilter;
};

type HabitatInsightsEcologicalTrendsQueryParams = {
    projectId: string;
    habitatInsightsCharts: string[];
    siteName: string[];
};

const insightsGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        statisticalComparison: builder.query<StatisticalComparisonQueryResult, GenericQueryParems>({
            query: params => ({
                body: statisticalComparisonQuery,
                variables: params,
            }),
        }),
        ecologicalTrends: builder.query<EcologicalTrendsQueryResult, InsightsQueryParams>({
            query: params => ({
                body: ecologicalTrendsQuery,
                variables: params,
            }),
        }),
        taxonomicComparison: builder.query<TaxonomicComparisonQueryResult, TaxonomicComparisonQueryParams>({
            query: params => ({
                body: taxonomicComparisonQuery,
                variables: params,
            }),
        }),
        communityComparisonChartData: builder.query<CommunityComparisonChartDataQueryResult, GenericQueryParems>({
            query: params => ({
                body: communityComparisonChartDataQuery,
                variables: params,
            }),
        }),
        communityComparisonSamples: builder.query<CommunityComparisonSamplesQueryResult, CommunityComparisonSamplesQueryParams>({
            query: params => ({
                body: communityComparisonSamplesQuery,
                variables: params,
            }),
        }),
        communityComparisonMarker: builder.query<communityComparisonMarkersQueryResult, CommunityComparisonMarkerQueryParams>({
            query: params => ({
                body: communityComparisonMarkerQuery,
                variables: params,
            }),
        }),
        sampleComparison: builder.query<SampleComparisonQueryResult, SampleComparisonQueryParams>({
            query: params => ({
                body: sampleComparisonQuery,
                variables: params,
            }),
        }),
        habitatInsightsEcologicalTrends: builder.query<
            HabitatInsightsEcologicalTrendsQueryResult,
            HabitatInsightsEcologicalTrendsQueryParams
        >({
            query: params => ({
                body: habitatInsightsEcologicalTrendsQuery,
                variables: { ...params, hlcaCharts: params.habitatInsightsCharts },
            }),
        }),
    }),
});

export const {
    useStatisticalComparisonQuery,
    useEcologicalTrendsQuery,
    useTaxonomicComparisonQuery,
    useCommunityComparisonChartDataQuery,
    useCommunityComparisonSamplesQuery,
    useCommunityComparisonMarkerQuery,
    useSampleComparisonQuery,
    useHabitatInsightsEcologicalTrendsQuery,
} = insightsGraphSlice;

export default insightsGraphSlice.reducer;
