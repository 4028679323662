import React, { CSSProperties } from 'react';
import { HabitatInsightsGeoJson } from 'src/shared/types';
import styles from './HabitatInsightsSamplesSidebar.module.scss';
import CustomAccordion from './components/CustomAccordion';
import { availableAreaColors } from 'src/shared/constants/colors';

type HabitatInsightsSamplesProps = {
    data: HabitatInsightsGeoJson[] | null;
};

const HabitatInsightsSampleSidebar = ({ data }: HabitatInsightsSamplesProps) => {
    return (
        <div className={styles.sidebarContainer}>
            <div className={styles.titleContainer}>
                <span className={styles.title}>Site Co-ords</span>
                <span>{`${data !== undefined && data?.length !== 0 && data?.length} sites`}</span>
            </div>
            <div className={styles.contentContainer}>
                {data?.map((item: HabitatInsightsGeoJson, index: number) => {
                    const content = (
                        <div className={styles.sidebarContentTable}>
                            <table>
                                <thead>
                                    <th>ID</th>
                                    <th>Lat</th>
                                    <th>Long</th>
                                </thead>
                                <tbody>
                                    {item.geometry.coordinates[0].map((coordinate: number[], index: number) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{coordinate[0].toFixed(2)}</td>
                                            <td>{coordinate[1].toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                    const title = (
                        <div className={styles.contentTitleContainer}>
                            <hr style={{ '--background-color': availableAreaColors[index] } as CSSProperties} />
                            <span>{item.properties.name}</span>
                            <span>{` ${item.geometry.coordinates[0].length} points`}</span>
                        </div>
                    );
                    return (
                        <div className={styles.content} key={index}>
                            <CustomAccordion title={title} content={content || null} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HabitatInsightsSampleSidebar;
