import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import CommunityComparison from './views/community-comparison/CommunityComparison';
import EcologicalTrends from './views/ecological-trends/EcologicalTrends';
import StatisticalComparison from './views/statistical-comparison/StatisticalComparison';
import TaxonomicComposition from './views/taxonomic-composition/TaxonomicComposition';
import SampleComparison from './views/sample-comparison/SampleComparison';
import HabitatInsightsEcologicalTrends from './views/habitat-insights-ecological-trends/HabitatInsightsEcologicalTrends';
import { getInsightRoutesAvailability } from 'src/routes/state/routesSelector';
import { useAppSelector } from 'src/store';
import { IndividualRouteSetting, InsightViews } from 'src/routes/routesConfig';

export const insightRouteSettings: { [key in InsightViews]: IndividualRouteSetting } = {
    ecologicalTrends: {
        name: 'Ecological Trends',
        path: '/ecological-trends',
        handler: <EcologicalTrends />,
    },
    habitatInsightsEcologicalTrends: {
        name: 'Ecological Trends',
        path: '/habitat-insights-ecological-trends',
        handler: <HabitatInsightsEcologicalTrends />,
    },
    sampleComparison: {
        name: 'Sample Comparison',
        path: '/sample-comparison',
        handler: <SampleComparison />,
    },
    statisticalComparison: {
        name: 'Statistical Comparison',
        path: '/statistical-comparison',
        handler: <StatisticalComparison />,
    },
    taxonomicComposition: {
        name: 'Taxonomic Composition',
        path: '/taxonomic-composition',
        handler: <TaxonomicComposition />,
    },
    communityComparison: {
        name: 'Community Comparison',
        path: '/community-comparison',
        handler: <CommunityComparison />,
    },
};

const Insights = () => {
    const routesConfig = useAppSelector(getInsightRoutesAvailability);

    return (
        <Routes>
            {Object.keys(routesConfig).map(routeKey => {
                const isAvailable = routesConfig[routeKey as InsightViews];
                const routeSetting = insightRouteSettings[routeKey as InsightViews];

                return (
                    <Route
                        path={routeSetting.path}
                        element={
                            <ProtectedRoute isAllowed={isAvailable} redirectPath={'/projects'}>
                                {routeSetting.handler}
                            </ProtectedRoute>
                        }
                        key={routeSetting.name}
                    />
                );
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default Insights;
