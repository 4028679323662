import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';
import { IucnSampleFilter } from 'src/shared/types';
import { fishMetricsQuery, FishMetricsQueryResult } from '../api/queries/fishMetricsQuery';
import { iucnMetricsQuery, IucnMetricsQueryResult } from '../api/queries/iucnMetricsQuery';
import { evolutionaryMetricsQuery, EvolutionaryMetricsQueryResult } from '../api/queries/evolutionaryMetricsQuery';
import { speciesRichnessMetricsQuery, SpeciesRichnessMetricsQueryResult } from '../api/queries/speciesRichnessMetricsQuery';
import {
    bacterialFunctionalDiversityMetricsQuery,
    BacterialFunctionalDiversityMetricsQueryResult,
} from '../api/queries/bacterialFunctionalDiversityMetricsQuery';

import {
    fungalFunctionalDiversityMetricsQuery,
    FungalFunctionalDiversityMetricsQueryResult,
} from '../api/queries/fungalFunctionalDiversityMetricsQuery';

type GenericMetricQueryParams = {
    projectId: string;
    habitatAssay: string;
    fromDate?: string;
    toDate?: string;
};

type GenericSamplesQueryParams = {
    projectId: string;
    samples: IucnSampleFilter;
};

const metricsGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        fishMetrics: builder.query<FishMetricsQueryResult, GenericMetricQueryParams>({
            query: params => ({
                body: fishMetricsQuery,
                variables: params,
            }),
        }),
        iucnMetrics: builder.query<IucnMetricsQueryResult, GenericSamplesQueryParams>({
            query: params => ({
                body: iucnMetricsQuery,
                variables: params,
            }),
        }),
        evolutionaryMetrics: builder.query<EvolutionaryMetricsQueryResult, GenericMetricQueryParams>({
            query: params => ({
                body: evolutionaryMetricsQuery,
                variables: params,
            }),
        }),
        speciesRichnessMetrics: builder.query<SpeciesRichnessMetricsQueryResult, GenericMetricQueryParams>({
            query: params => ({
                body: speciesRichnessMetricsQuery,
                variables: params,
            }),
        }),
        bacterialFunctionalDiversityMetrics: builder.query<BacterialFunctionalDiversityMetricsQueryResult, GenericMetricQueryParams>({
            query: params => ({
                body: bacterialFunctionalDiversityMetricsQuery,
                variables: params,
            }),
        }),
        fungalFunctionalDiversityMetrics: builder.query<FungalFunctionalDiversityMetricsQueryResult, GenericMetricQueryParams>({
            query: params => ({
                body: fungalFunctionalDiversityMetricsQuery,
                variables: params,
            }),
        }),
    }),
});

export const {
    useFishMetricsQuery,
    useIucnMetricsQuery,
    useSpeciesRichnessMetricsQuery,
    useEvolutionaryMetricsQuery,
    useBacterialFunctionalDiversityMetricsQuery,
    useFungalFunctionalDiversityMetricsQuery,
} = metricsGraphSlice;

export default metricsGraphSlice.reducer;
