import React, { useState } from 'react';
import { NewProjectUserFormState } from 'src/app/admin/views/user-management/state/userManagementSlice';
import Checkbox from 'src/shared/components/checkbox/Checkbox';
import { getRoleLabel } from 'src/app/admin/views/user-management/components/userManagementHelpers';
import { useAppSelector } from 'src/store';
import { getNewProjectUserFormState, getUserManagementGeneralUiState } from '../../state/userManagementSelector';
import useAuthService from 'src/shared/hooks/useAuthService';
import { UserType, Role } from 'src/shared/types';

type AssignAdminRolesProps = {
    roles: Role[];
    defaultRoles?: Role[] | null;
    onChange: (fragment: Partial<NewProjectUserFormState['formState']>) => void;
};
const AssignAdminRoles = (props: AssignAdminRolesProps) => {
    const formState = useAppSelector(getNewProjectUserFormState);
    const { roles, onChange, defaultRoles = [] } = props;
    const [checkedRoles, setCheckedRoles] = useState<Role[] | null>(defaultRoles);
    const generalUiState = useAppSelector(getUserManagementGeneralUiState);
    const { hasCustomerAdminInUsers } = generalUiState;
    const { isCurrentUserSuperAdmin } = useAuthService();

    const handleCheckboxChange = (isChecked: boolean, roleName: string) => {
        const updatedRoles = isChecked ? [...roles, roleName] : roles.filter((role: string) => role !== roleName);
        onChange({ roles: updatedRoles as Role[] });
        setCheckedRoles(updatedRoles as Role[]);
    };

    const isInternalUser = formState.userType === UserType.INTERNAL;
    const canCreateCustomerAdmin = !isInternalUser;
    return (
        <>
            {canCreateCustomerAdmin && (
                <Checkbox
                    label={getRoleLabel(Role.CUSTOMER_ADMIN)}
                    onChange={value => handleCheckboxChange(value, Role.CUSTOMER_ADMIN)}
                    checked={Boolean(checkedRoles?.includes(Role.CUSTOMER_ADMIN))}
                    disabled={hasCustomerAdminInUsers}
                />
            )}
            {isCurrentUserSuperAdmin && isInternalUser && (
                <Checkbox
                    label='Internal Project Admin'
                    onChange={value => handleCheckboxChange(value, Role.NM_PROJECT_ADMIN)}
                    checked={Boolean(checkedRoles?.includes(Role.NM_PROJECT_ADMIN))}
                />
            )}
        </>
    );
};

export default AssignAdminRoles;
