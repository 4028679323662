import React, { useEffect } from 'react';

import { UserType } from 'src/shared/types';

import RadioGroup from 'src/shared/components/radio-group/RadioGroup';

import styles from './UserTypeForm.module.scss';
import useAuthService from 'src/shared/hooks/useAuthService';
import { setFormState } from 'src/app/admin/views/user-management/state/userManagementSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getNewProjectUserFormState } from 'src/app/admin/views/user-management/state/userManagementSelector';

const UserTypeForm = () => {
    const { isCurrentUserSuperAdmin, isCurrentUserOnboardingManager } = useAuthService();
    const dispatch = useAppDispatch();
    const formState = useAppSelector(getNewProjectUserFormState);

    const userTypeOptions = [
        { label: 'Internal (NatureMetrics)', value: UserType.INTERNAL, isDisabled: isCurrentUserOnboardingManager },
        { label: 'External (Customer)', value: UserType.CUSTOMER },
    ];

    useEffect(() => {
        dispatch(
            setFormState({
                userType: isCurrentUserSuperAdmin ? UserType.INTERNAL : UserType.CUSTOMER,
            })
        );
    }, []);

    const onChange = (type: string) => {
        dispatch(
            setFormState({
                userType: type,
            })
        );
    };

    return (
        <div className={styles.userTypeForm}>
            <div className={styles.stepIndicator}>Step 1 of 2</div>
            <div className={styles.sectionHeading}>User details</div>
            <div className={styles.fields}>
                <div className={styles.label}>What type of user do you want to add to this organisation?</div>
                <RadioGroup
                    options={userTypeOptions}
                    onChange={value => onChange(value as string)}
                    selectedOption={formState.userType}
                    className={styles.userType}
                    labelClassName={styles.userTypeLabel}
                />
            </div>
        </div>
    );
};

export default UserTypeForm;
