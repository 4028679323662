import { Action, createAsyncThunk, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import {
    insightRoutesAvailability,
    projectRoutesAvailability,
    tableRoutesAvailability,
    adminRoutesAvailability,
    appRoutesAvailability,
    AppViews,
    AdminViews,
    InsightViews,
    ProjectViews,
    TableViews,
    SurveyViews,
    MapViews,
    surveyRoutesAvailability,
    mapRoutesAvailability,
} from '../routesConfig';
import routesStorageService from '../services/routes-storage-service';
import { RootState } from 'src/store';

export type RoutesConfig = {
    appRoutesAvailability: { [key in AppViews]: boolean };
    insightRoutesAvailability: { [key in InsightViews]: boolean };
    projectRoutesAvailability: { [key in ProjectViews]: boolean };
    surveyRoutesAvailability: { [key in SurveyViews]: boolean };
    tableRoutesAvailability: { [key in TableViews]: boolean };
    adminRoutesAvailability: { [key in AdminViews]: boolean };
    mapRoutesAvailability: { [key in MapViews]: boolean };
};

type RoutesConfigKeys = keyof RoutesConfig;

export type RoutesState = {
    routesConfig: RoutesConfig;
};

export const initialRoutesState: RoutesState = {
    routesConfig: {
        appRoutesAvailability: appRoutesAvailability,
        insightRoutesAvailability: insightRoutesAvailability,
        projectRoutesAvailability: projectRoutesAvailability,
        surveyRoutesAvailability: surveyRoutesAvailability,
        tableRoutesAvailability: tableRoutesAvailability,
        adminRoutesAvailability: adminRoutesAvailability,
        mapRoutesAvailability: mapRoutesAvailability,
    },
};

export const loadStoredRoutesConfig = createAsyncThunk('routes/loadStoredRoutesConfig', async () => {
    return routesStorageService.getCurrentRoutesConfig() || initialRoutesState.routesConfig;
});

export const resetRoutes = (): ThunkAction<void, RootState, void, Action<string>> => dispatch => {
    dispatch(routesSlice.actions.resetRoutes());

    routesStorageService.clearAll();
};

const routesSlice = createSlice({
    name: 'routes',
    initialState: initialRoutesState,
    reducers: {
        updateRoutesConfig(
            state,
            action: PayloadAction<
                Partial<{
                    [K in RoutesConfigKeys]: RoutesConfig[K];
                }>
            >
        ) {
            state.routesConfig = { ...state.routesConfig, ...action.payload };
            routesStorageService.setCurrentRoutesConfig(state.routesConfig);
        },

        restoreRoutesConfig(state, action: PayloadAction<RoutesConfig>) {
            state.routesConfig = action.payload;
        },
        resetRoutes(state) {
            // eslint-disable-next-line
            state.routesConfig = initialRoutesState.routesConfig;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadStoredRoutesConfig.fulfilled, (state, action) => {
            if (action.payload) {
                state.routesConfig = action.payload;
            }
        });
    },
});

export default routesSlice.reducer;

export const { updateRoutesConfig } = routesSlice.actions;
