import React, { useState, useEffect } from 'react';
import uniq from 'lodash/uniq';
import moment from 'moment';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import HabitatAssayLozenges from 'src/shared/components/lozenges/HabitatAssayLozenges';
import useProject from 'src/shared/hooks/useProject';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { AppName } from 'src/global/globalConfig';
import { SelectOption } from 'src/shared/components/select/Select';
import { getCurrentSampleComparisonUIState } from 'src/app/insights/state/sample-comparison/sampleComparisonSelector';
import { useAppDispatch, useAppSelector } from 'src/store';
import { updateFilters, SampleComparisonFiltersState } from 'src/app/insights/state/sample-comparison/sampleComparisonSlice';
import Loader, { LinearLoader } from 'src/shared/components/loader/Loader';
import { useSampleComparisonQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import { transformData } from 'src/app/insights/views/sample-comparison/SampleComparisonHelpers';
import HorizontalBarChart from 'src/shared/components/charts/horizontal-bar-chart/HorizontalBarChart';
import { getDateQuarterSelectOption } from 'src/shared/utils/dateHelpers';
import SampleComparisonFilters from './components/sample-comparison-filters/SampleComparisonFilters';
import { chartExplainerSlugKey } from 'src/shared/constants/chartExplainerKeys';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { HorizontalBarChartData } from 'src/shared/components/charts/horizontal-bar-chart/HorizontalBarChart';
import styles from './SampleComparison.module.scss';

const PAGE_TITLE = 'Sample Comparison';
const SampleComparison = () => {
    const {
        currentProjectId,
        currentProjectDetails,
        currentProjectFilters,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        setCurrentProjectHabitatAssayType,
        getColourForArea,
    } = useProject();

    const currentProjectBreadcrumbDetails = useProjectBreadcrumb('Sample Comparison');
    const dispatch = useAppDispatch();

    const [projectDateOptionList, setProjectDateOptionList] = useState<SelectOption[]>([]);

    const uiState = useAppSelector(getCurrentSampleComparisonUIState);
    const {
        filters: { chartId, sortBy, fromDate },
        chartTitle,
    } = uiState;

    const chartTitleEl = (
        <span>
            {chartTitle}
            {chartExplainerSlugKey[chartId] && <QuestionButtonHelp type='api' placement='right' slug={chartExplainerSlugKey[chartId]} />}
        </span>
    );

    const { currentData, isFetching } = useSampleComparisonQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                habitatAssay: [habitatAssayType?.key || ''],
                fromDate: fromDate,
                toDate: moment(fromDate).endOf('Q').format('YYYY-MM-DD'),
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType || !fromDate,
        }
    );

    const onFiltersChange = (fragment: Partial<SampleComparisonFiltersState>) => {
        dispatch(updateFilters(fragment));
    };

    useEffect(() => {
        if (!currentProjectDetails) {
            return;
        }
        const samples = currentProjectDetails.samples;
        const uniqueSampleDates = uniq(samples.map(entry => moment(entry.sampleCollectedDatetime).startOf('Q').valueOf()))
            .sort()
            .reverse();

        const startDateOptions = uniqueSampleDates.map(entry => getDateQuarterSelectOption(entry)).reverse();
        setProjectDateOptionList(startDateOptions);

        onFiltersChange({
            fromDate: fromDate || startDateOptions[0]?.value,
        });
    }, [currentProjectDetails]);

    if (isFetching || !currentProjectDetails) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    if (!currentData) {
        return null;
    }

    const { samples } = currentData.sampleComparisonData;
    const [sortProperty, sortOrder] = sortBy.split('_');

    const transformedData = transformData(samples, chartId, getColourForArea, sortProperty as keyof HorizontalBarChartData, sortOrder);
    const onHabitatAssayTypeChange = (value: string) => {
        onFiltersChange({ chartId: '' });
        setCurrentProjectHabitatAssayType(value);
    };

    const lozenges = (
        <HabitatAssayLozenges
            options={currentProjectFilters?.habitatAssayTypeOptions || []}
            selectedValue={habitatAssayType?.key || ''}
            onChange={onHabitatAssayTypeChange}
            app={AppName.SAMPLE_COMPARISON}
        />
    );

    const mainContent = (
        <div>
            <div className={styles.chartContainer}>
                <HorizontalBarChart data={transformedData} title={chartTitleEl} yAxisTile='Sample ID' />
            </div>
        </div>
    );

    return (
        <StandardAppLayout
            breadcrumbs={currentProjectBreadcrumbDetails}
            lozenges={lozenges}
            title={PAGE_TITLE}
            subTitle='Compare metric values across samples'
            mainContent={mainContent}
            filterSection={<SampleComparisonFilters projectDateOptionList={projectDateOptionList} />}
            page={AppName.SAMPLE_COMPARISON}
        />
    );
};

export default SampleComparison;
