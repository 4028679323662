import React, { ReactNode, useState } from 'react';

import styles from './SuccessMessage.module.scss';
import { Check } from '@mui/icons-material';

const SuccessMessage = ({ message }: { message: ReactNode }) => {
    const [hasDismissed, setDismissed] = useState(false);

    if (hasDismissed) {
        return null;
    }

    return (
        <div className={styles.successMessage}>
            <Check />
            <div className={styles.message} data-testid='message'>
                {message}
            </div>
            <div className={styles.dismiss} onClick={() => setDismissed(true)}>
                Dismiss
            </div>
        </div>
    );
};

export default SuccessMessage;
