import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { SortDirection } from 'src/shared/types';

export type SampleComparisonFiltersState = {
    habitatAssayType: string;
    chartId: string;
    sortBy: string;
    fromDate: string;
};

const defaultFiltersState = {
    habitatAssayType: '',
    chartId: '',
    sortBy: `value_${SortDirection.DESCENDING}`,
    fromDate: '',
};

export type SampleComparisonUIState = {
    filters: SampleComparisonFiltersState;
    chartTitle: string | null;
};

export const defaultUiState = {
    filters: { ...defaultFiltersState },
    chartTitle: '',
};

type SampleComparisonState = {
    uiState: {
        [projectId: string]: SampleComparisonUIState;
    };
};
const initialState: SampleComparisonState = {
    uiState: {},
};

export const updateUiState =
    (fragment: Partial<SampleComparisonUIState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;
        if (projectId) {
            dispatch(
                sampleComparisonSlice.actions.updateUIState({
                    projectId,
                    fragment,
                })
            );
        }
    };

export const updateFilters =
    (fragment: Partial<SampleComparisonFiltersState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;

        if (projectId) {
            dispatch(
                sampleComparisonSlice.actions.updateFilters({
                    projectId,
                    fragment,
                })
            );
        }
    };

const ensureProjectUIStatePresence = (state: SampleComparisonState, projectId: string) => {
    if (!state.uiState[projectId]) {
        state.uiState[projectId] = {
            filters: { ...defaultFiltersState },
            chartTitle: '',
        };
    }
};

const sampleComparisonSlice = createSlice({
    name: 'sampleComparison',
    initialState,
    reducers: {
        updateFilters(state, action: PayloadAction<{ projectId: string; fragment: Partial<SampleComparisonFiltersState> }>) {
            const { projectId, fragment } = action.payload;
            ensureProjectUIStatePresence(state, projectId);
            state.uiState[projectId].filters = {
                ...(state.uiState[projectId].filters as SampleComparisonFiltersState),
                ...fragment,
            };
        },
        updateUIState(state, action: PayloadAction<{ projectId: string; fragment: Partial<SampleComparisonUIState> }>) {
            const { projectId, fragment } = action.payload;
            ensureProjectUIStatePresence(state, projectId);
            state.uiState[projectId] = {
                ...(state.uiState[projectId] as SampleComparisonUIState),
                ...fragment,
            };
        },
    },
});

export default sampleComparisonSlice.reducer;
