import React, { Suspense } from 'react';

import App from '../app/App';
import useRestoredReduxState from './useRestoredReduxState';

// Importing the leaflet css at the root level for the maps to work
import 'leaflet/dist/leaflet.css';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import PagenotfoundImage from 'src/assets/images/general/404.webp';
import { ErrorBoundary } from 'react-error-boundary';

import styles from './Root.module.scss';
import { PrimaryButton } from 'src/shared/components/button/Button';
import customersStorageService from 'src/app/customers/services/customers-storage-service';
import projectsStorageService from 'src/app/projects/services/projects-storage-service';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';

const ErrorScreen = ({ error }: { error?: Error }) => {
    const onClick = () => {
        customersStorageService.clearAll();
        projectsStorageService.clearAll();
        window.location.reload();
    };
    const mainContent = (
        <div className={styles.errorBoundaryContainer}>
            <div className={styles.left}>
                <div className={styles.title}>Oops!</div>
                <div className={styles.subTitle}>Something went wrong.</div>
                <div className={styles.details}>
                    <ErrorMessage message={error?.message || 'Unknown error.'} />
                </div>
                <div className={styles.solution}>
                    Please try refreshing using the button below or contact NatureMetrics support if the issue persists.
                </div>
                <div className={styles.buttonWrapper}>
                    <PrimaryButton onClick={onClick}>Try again</PrimaryButton>
                </div>
            </div>
            <div className={styles.right}>
                <img src={PagenotfoundImage} />
            </div>
        </div>
    );
    return <StandardAppLayout mainContent={mainContent} />;
};

export const Root = () => {
    // Restore preserved store data from local and session storage
    useRestoredReduxState();

    return (
        <div>
            <ErrorBoundary fallbackRender={ErrorScreen}>
                <Suspense>
                    <App />
                </Suspense>
            </ErrorBoundary>
        </div>
    );
};

export default Root;
