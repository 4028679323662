import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import noop from 'lodash/noop';

import { AssociatedCustomer, Role, Access, UserType, Status } from 'src/shared/types';

import { useResendInvitationEmailMutation } from 'src/app/admin/views/user-management/state/api/userManagementGraphSlice';

import { SecondaryButton } from 'src/shared/components/button/Button';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import StatusIndicator from 'src/shared/components/status-indicator/StatusIndicator';
import InfoMessage from 'src/shared/components/info-message/InfoMessage';

import UserDetails from 'src/app/admin/views/user-management/components/user-details/UserDetails';
import AssignProjects from 'src/app/admin/views/user-management/components/assign-projects/AssignProjects';
import { getUserStatusFromEnum } from 'src/app/admin/views/user-management/components/user-management-list/UserManagementList';

import useCustomer from 'src/app/customers/hooks/useCustomer';
import useBoolean from 'src/shared/hooks/useBoolean';

import styles from './ViewProjectUser.module.scss';
import { getEditProjectUserFormState } from '../../state/userManagementSelector';
import { useAppDispatch, useAppSelector } from 'src/store';
import ExpandableSection from 'src/shared/components/expandable-section/ExpandableSection';
import { useProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import StatusChip from 'src/shared/components/status-chip/StatusChip';
import usePermissions from 'src/shared/hooks/usePermissions';
import { batch } from 'react-redux';
import { setEditUserFormMode, setEditUserView, updateGeneralUiState } from '../../state/userManagementSlice';

export const ViewProjectUser = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isResendBtnDisabled, setResendBtnDisabled] = useBoolean(false);
    const [isOrgSectionOpen, setIsOrgSectionOpen] = useBoolean(false);
    const { allCustomers, currentCustomerId } = useCustomer();
    const [resendInvitationEmail] = useResendInvitationEmailMutation();
    const formState = useAppSelector(getEditProjectUserFormState);
    const isActiveOrInvited = formState.status === 'Active' || formState.status === 'Invited';
    const isAssignedToCustomer = formState.customers.some(customer => customer.customerId === currentCustomerId && customer.active);
    const { hasCustomerLevelRole } = usePermissions();
    const dispatch = useAppDispatch();

    const { currentData: projectsData } = useProjectsQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: !currentCustomerId,
        }
    );

    const getCustomerName = (customerId: string) => {
        const customerDetail = allCustomers.find(customer => customer.customerId === customerId);
        return customerDetail?.customerName || '';
    };

    const handleResendEmail = async () => {
        try {
            await resendInvitationEmail({
                email: formState.email,
            }).unwrap;
            setResendBtnDisabled.on();
        } catch (error) {
            console.error('rejected', error);
            setErrorMessage('Unable to resend the invitation email. Please try again later.');
        }
    };

    const resendEmail = formState.status === 'Invited' && (
        <div className={styles.resendEmailContainer}>
            <span className={styles.row}>
                <span className={styles.info}>
                    <InfoOutlinedIcon />
                    <p>This user has not accepted their email invitation.</p>
                </span>
            </span>
            <span className={styles.row}>
                <SecondaryButton className={styles.button} onClick={handleResendEmail} disabled={isResendBtnDisabled}>
                    <EmailOutlinedIcon /> &nbsp; {isResendBtnDisabled ? 'Email invitation resent' : 'Resend email invitation'}
                </SecondaryButton>
            </span>
        </div>
    );

    const activeStatusIndicator = <StatusIndicator status={getUserStatusFromEnum(formState.status)} statusText={formState.status} />;

    const isUserCustomerAdmin = (customerList: AssociatedCustomer[], currentCustomerId: string) => {
        return customerList.some(
            (customer: AssociatedCustomer) => customer.roles?.includes(Role.CUSTOMER_ADMIN) && customer.customerId === currentCustomerId
        );
    };

    const userDetails = (
        <div className={styles.userDetails}>
            <UserDetails
                firstName={formState.firstName}
                lastName={formState.lastName}
                email={formState.email}
                status={formState.status}
                userType={formState.is_internal_user ? UserType.INTERNAL : UserType.CUSTOMER}
                isCustomerAdmin={isUserCustomerAdmin(formState.customers, currentCustomerId)}
                contactInfoClass={styles.userContact}
            />
            <div style={{ margin: '8px 0 0 60px' }}>
                <StatusChip label={formState.status} status={getUserStatusFromEnum(formState.status)} />
            </div>
            <StatusIndicator
                status={isAssignedToCustomer ? Status.SUCCESS : isActiveOrInvited ? Status.WARNING : Status.ERROR}
                statusText={
                    isAssignedToCustomer ? 'Assigned to this org' : isActiveOrInvited ? 'Invited to this org' : 'Unassigned from this org'
                }
            />
            {resendEmail}
            <span className={styles.sectionSeparator}></span>
        </div>
    );

    const otherOrgs = formState.customers.filter(customer => customer.customerId !== currentCustomerId);

    const expandedOrgDetailsContent = otherOrgs.length ? (
        <div className={styles.orgList}>
            {otherOrgs.map(otherOrg => (
                <div className={styles.orgDetail} key={otherOrg.customerId}>
                    <div className={styles.orgTitle}>{getCustomerName(otherOrg.customerId)}</div>
                    {activeStatusIndicator}
                </div>
            ))}
        </div>
    ) : (
        <InfoMessage message='This user is not assigned to any other organisations' />
    );

    const orgDetails = (
        <ExpandableSection
            isExpanded={isOrgSectionOpen}
            onToggle={setIsOrgSectionOpen.toggle}
            expandedContent={
                <>
                    <div className={styles.orgSectionTitle}>User&#8217;s other organisations</div>
                    {expandedOrgDetailsContent}
                </>
            }
            collapsedContent={<div>User&#8217;s other organisations</div>}
        />
    );

    const { projects } = formState;
    const projectIdsForCurrentCustomer = projectsData?.customer.projects.map(project => project.projectId) || [];
    const filteredProjects = projects.filter(project => projectIdsForCurrentCustomer.includes(project.projectId));

    const projectDetails = filteredProjects.length > 0 && (
        <>
            <div className={styles.sectionHeading}>Project permissions</div>
            <AssignProjects
                formState={{ projects: formState.projects, status: formState.status, is_internal_user: formState.is_internal_user }}
                onFormChange={noop}
                formMode={Access.VIEW}
                disabled={!isActiveOrInvited}
            />
        </>
    );

    const assignStatusText = isAssignedToCustomer ? 'Unassign' : 'Assign';
    const assignStatusIcon = isAssignedToCustomer ? <PersonOffIcon /> : <PersonIcon />;
    // Customer admin can assign or unassign users from an organization
    const isCurrentUserCustomerAdmin = hasCustomerLevelRole({
        customerId: currentCustomerId,
        role: Role.CUSTOMER_ADMIN,
    });
    const isAllowedToAssignOrUnassign = isCurrentUserCustomerAdmin;

    const organisationAssignButton = isAllowedToAssignOrUnassign ? (
        <SecondaryButton
            onClick={() => {
                batch(() => {
                    dispatch(updateGeneralUiState({ shouldShowViewUserModal: false, shouldShowEditUserModal: true }));
                    dispatch(setEditUserFormMode(Access.UPDATE));
                    dispatch(setEditUserView(isAssignedToCustomer ? 'unassign' : 'reassign'));
                });
            }}
        >
            {assignStatusIcon}&nbsp;{`${assignStatusText} ${isAssignedToCustomer ? 'from' : 'to'} this organisation`}
        </SecondaryButton>
    ) : null;

    return (
        <div className={styles.viewProjectUser}>
            <div className={styles.title}>View user</div>
            <div className={styles.popupSections}>
                {userDetails}
                {orgDetails}
                <span className={styles.sectionSeparator}></span>
                {projectDetails}
                {organisationAssignButton}
            </div>
            <div>{errorMessage && <ErrorMessage message={errorMessage} />}</div>
        </div>
    );
};
