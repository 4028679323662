import React from 'react';
import startCase from 'lodash/startCase';

import BoxPlot from 'src/app/insights/views/statistical-comparison/components/box-plot/BoxPlot';
import HexagonBoxPlot from 'src/assets/images/insights/hexagon-boxplots.png';
import { AppName } from 'src/global/globalConfig';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import HabitatAssayLozenges from 'src/shared/components/lozenges/HabitatAssayLozenges';
import NoData from 'src/shared/components/no-data/NoData';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import Switch from 'src/shared/components/switch/Switch';
import useProject from 'src/shared/hooks/useProject';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useStatisticalComparisonQuery } from '../../state/api/insightsGraphSlice';
import { StatisticalComparisonBoxData } from '../../state/api/queries/statisticalComparisonQuery';
import { getCurrentStatisticalComparisonUIState } from '../../state/statistical-comparison/statisticalComparisonSelector';
import { StatisticalComparisonFiltersState, updateFilters } from '../../state/statistical-comparison/statisticalComparisonSlice';
import StatisticalComparisonFilters from './components/statistical-comparison-filters/StatisticalComparisonFilters';

import styles from './StatisticalComparison.module.scss';
import Loader from 'src/shared/components/loader/Loader';

const PAGE_TITLE = 'Statistical Comparison';
const StatisticalComparison = () => {
    const {
        currentProjectDetails,
        currentProjectFilters,
        currentProjectId,
        setCurrentProjectHabitatAssayType,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        getColourForArea,
    } = useProject();
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);

    const dispatch = useAppDispatch();

    const uiState = useAppSelector(getCurrentStatisticalComparisonUIState);

    const {
        filters: { metric, comparing, comparingWith, shouldShowBoxPlot, shouldShowSamplePoints, samplingYear },
    } = uiState;

    const onFiltersChange = (fragment: Partial<StatisticalComparisonFiltersState>) => {
        dispatch(updateFilters(fragment));
    };

    const { currentData, isFetching } = useStatisticalComparisonQuery(
        {
            projectId: currentProjectId || '',
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    if (isFetching || !currentData || !currentProjectDetails) {
        return <Loader />;
    }

    const explainerSlugKey: { [key: string]: string } = {
        speciesrichness: 'species-richness',
        evolutionarydiversity: 'evolutionary-diversity',
        fishcommercialvalue: 'fish-commercial-value',
        fishfoodchainintegrity: 'food-chain-integrity',
        fishprevalenceofsensitivespecies: 'fish-prevalance-of-sensitive-species',
    };

    const metricInfo = currentProjectFilters?.habitatAssays
        .find(entry => entry.key === habitatAssayType?.key)
        ?.metrics.find(entry => entry.metricKey === metric);

    let dataForSelectedYear: StatisticalComparisonBoxData[] = [];
    let isStatisticallyDifferent = false;

    let mainContent = null;

    if (currentData.statisticalComparison && comparing && comparingWith) {
        const statisticalComparisonData = currentData.statisticalComparison?.data;
        const boxes = statisticalComparisonData
            .find(entry => entry.metric === metric)
            ?.years.find(entry => entry.samplingYear === samplingYear)?.boxes;

        dataForSelectedYear = [
            boxes?.find(entry => entry.variable.value === comparing) as StatisticalComparisonBoxData,
            boxes?.find(entry => entry.variable.value === comparingWith) as StatisticalComparisonBoxData,
        ];

        isStatisticallyDifferent =
            dataForSelectedYear[0]?.comparisons.find(entry => entry.comparedTo === dataForSelectedYear[1]?.variable.value)?.sig_diff ||
            false;
    }

    if (currentData.statisticalComparison) {
        const hasPlotData = dataForSelectedYear[0] && dataForSelectedYear[1];

        mainContent = (
            <div className={styles.chartContainer}>
                <div className={styles.statisticalComparison}>
                    <StatisticalComparisonFilters />
                    {hasPlotData ? (
                        <div className={styles.plot}>
                            <div className={styles.plotHeader}>
                                <div className={styles.plotHeaderTitle}>
                                    The <b>{startCase(metricInfo?.metricName)}</b> of{' '}
                                    <span style={{ color: getColourForArea(comparing) }}>{comparing}</span> is
                                    <br />
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        <b>{isStatisticallyDifferent ? '' : 'not'} statistically different</b>
                                    </span>{' '}
                                    to <span style={{ color: getColourForArea(comparingWith) }}>{comparingWith}</span>
                                </div>

                                <div className={styles.plotHeaderHelp}>
                                    <div className={styles.help}>
                                        <QuestionButtonHelp
                                            type='api'
                                            placement='bottom'
                                            label='How was this calculated?'
                                            slug='statistical-comparison-calculation-method'
                                            detailedExplainerSlug='statistical-comparison-te'
                                        />
                                    </div>

                                    <div className={styles.help}>
                                        <QuestionButtonHelp
                                            type='api'
                                            placement='bottom'
                                            label='What does statistically different mean?'
                                            slug='statistical-comparison-statistically-different-meaning'
                                        />
                                    </div>
                                </div>

                                <div className={styles.plotHeaderControls}>
                                    <Switch
                                        checked={shouldShowBoxPlot}
                                        label='Show Box Plots'
                                        onChange={status => onFiltersChange({ shouldShowBoxPlot: status })}
                                        size='medium'
                                    />
                                    <Switch
                                        checked={shouldShowSamplePoints}
                                        label='Show Sample Points'
                                        onChange={status => onFiltersChange({ shouldShowSamplePoints: status })}
                                        size='medium'
                                    />
                                </div>
                            </div>

                            <div className={styles.plotContainer}>
                                <div className={styles.plotMetric}>
                                    {metricInfo?.metricName || null}
                                    {metricInfo?.metricKey && explainerSlugKey[metricInfo.metricKey] && (
                                        <QuestionButtonHelp type='api' placement='right' slug={explainerSlugKey[metricInfo.metricKey]} />
                                    )}
                                </div>
                                <div className={styles.plotContainerPlot}>
                                    {dataForSelectedYear[0] && dataForSelectedYear[1] && (
                                        <BoxPlot
                                            data={dataForSelectedYear as any}
                                            metricName={metricInfo?.metricName || ''}
                                            variable1Label={comparing}
                                            variable2Label={comparingWith}
                                            shouldShowBoxPlots={shouldShowBoxPlot}
                                            shouldShowSamplePoints={shouldShowSamplePoints}
                                            colours={[getColourForArea(comparing), getColourForArea(comparingWith)]}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className={styles.plotFooter}>
                                <QuestionButtonHelp
                                    type='api'
                                    placement='bottom'
                                    label='How do I read this visualisation'
                                    slug='box-plot-anatomy'
                                    maxWidth={650}
                                />
                            </div>
                        </div>
                    ) : (
                        <NoData
                            image={HexagonBoxPlot}
                            text={`There is no data available for the sample group ${!dataForSelectedYear[0] ? comparing : comparingWith}`}
                            subText='Please select a different sample group or another Habitat/Assay.'
                        />
                    )}
                </div>
            </div>
        );
    } else {
        mainContent = (
            <NoData
                image={HexagonBoxPlot}
                text='This chart requires a minimum of two sample groups to be able to display data'
                subText='Please select another Habitat/Assay.'
            />
        );
    }

    const lozenges = (
        <HabitatAssayLozenges
            options={currentProjectFilters?.habitatAssayTypeOptions || []}
            selectedValue={habitatAssayType?.key || ''}
            onChange={habitatAssayType => {
                setCurrentProjectHabitatAssayType(habitatAssayType);
                onFiltersChange({ samplingYear: '', metric: '', comparing: '', comparingWith: '' });
            }}
            app={AppName.STATISTICAL_COMPARISON}
        />
    );

    return (
        <StandardAppLayout
            lozenges={lozenges}
            mainContent={mainContent}
            title={PAGE_TITLE}
            subTitle={<CMSArticle slug='statistical-comparison-description' />}
            breadcrumbs={currentProjectBreadcrumbDetails}
            page={AppName.STATISTICAL_COMPARISON}
        />
    );
};

export default StatisticalComparison;
