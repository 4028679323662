import React from 'react';
import styles from './StatusChip.module.scss';
import { Status } from 'src/shared/types';
import classNames from 'classnames';

export type ChipProps = {
    label: string;
    status: Status;
};

const StatusChip = (props: ChipProps) => {
    const { label, status } = props;

    const chipClassNames = classNames(styles.statusChip, styles[`statusChip${status}`]);
    return <div className={chipClassNames}>{label}</div>;
};

export default StatusChip;
