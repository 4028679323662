import React from 'react';

import styles from './TaxonomicCompositionInlineFilters.module.scss';
import InputLabel from '@mui/material/InputLabel';
import {
    TaxonomicCompositionFiltersState,
    columnOptions,
    defaultFiltersState,
    metricOptions,
    updateFilters,
} from 'src/app/insights/state/taxonomic-composition/taxonomicCompositionSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import SelectMultiple from 'src/shared/components/select-multiple/SelectMultiple';
import { getCurrentTaxonomicCompositionUIState } from 'src/app/insights/state/taxonomic-composition/taxonomicCompositionSelector';
import { columnOrder, getDefaultColumnsForAssayType } from '../taxonomic-composition-chart/taxonomicCompositionChartHelpers';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import useProject from 'src/shared/hooks/useProject';
import CloseIcon from '@mui/icons-material/Close';
import { SecondaryButton } from 'src/shared/components/button/Button';

type TaxonomicCompositionInlineFiltersProps = {
    projectDateOptionList: SelectOption[];
};

const TaxonomicCompositionInlineFilters = (props: TaxonomicCompositionInlineFiltersProps) => {
    const dispatch = useAppDispatch();
    const { currentProjectFilters, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();

    const { projectDateOptionList } = props;

    const uiState = useAppSelector(getCurrentTaxonomicCompositionUIState);
    const {
        filters: { selectedColumns, fromDate, metric, areas },
    } = uiState;

    const onFiltersChange = (fragment: Partial<TaxonomicCompositionFiltersState>) => {
        dispatch(updateFilters({ ...fragment, highlightedBranchNodeId: null, visibleBranchNodeId: null }));
    };

    const onColumnOrderChange = (values: string[]) => {
        onFiltersChange({ selectedColumns: columnOrder.filter(column => values.includes(column)) });
    };

    const handleFiltersReset = () => {
        onFiltersChange({
            ...defaultFiltersState,
            areas: newAreaOptions.map(entry => entry.value),
            fromDate: projectDateOptionList[0].value,
            selectedColumns: getDefaultColumnsForAssayType(habitatAssayType?.key.split('-')[1] || ''),
        });
    };

    const newAreaOptions = [...(currentProjectFilters?.areaOptions || [])];

    return (
        <div className={styles.filterGroup}>
            <div className={styles.filters}>
                <div className={styles.selectGroup}>
                    <div className={styles.selectInput}>
                        <InputLabel className={styles.filterLabel}>Display columns</InputLabel>
                        <SelectMultiple
                            width={200}
                            options={columnOptions}
                            onChange={onColumnOrderChange}
                            values={selectedColumns}
                            showClearButton={true}
                        />
                    </div>

                    <div className={styles.selectInput}>
                        <InputLabel className={styles.filterLabel}>Time Point</InputLabel>
                        <Select
                            options={projectDateOptionList}
                            onChange={value => onFiltersChange({ fromDate: value })}
                            selectedValue={fromDate || ''}
                        />
                    </div>

                    <div className={styles.selectInput}>
                        <InputLabel className={styles.filterLabel}>Sample Group</InputLabel>
                        <SelectMultiple
                            options={newAreaOptions || []}
                            onChange={values => onFiltersChange({ areas: values })}
                            values={areas}
                            optionsWidth={340}
                        />
                    </div>

                    <div className={styles.selectInput}>
                        <InputLabel className={styles.filterLabel}>Metric</InputLabel>
                        <Select options={metricOptions} onChange={value => onFiltersChange({ metric: value })} selectedValue={metric} />
                    </div>
                </div>
                <div className={styles.resetButtonBucket}>
                    <SecondaryButton className={styles.resetButton} onClick={handleFiltersReset}>
                        <span>Reset filters</span> <CloseIcon />
                    </SecondaryButton>
                </div>
            </div>
        </div>
    );
};

export default TaxonomicCompositionInlineFilters;
