import React, { ReactNode, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../button/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import styles from './Confirm.module.scss';

type ConfirmProps = {
    children: ReactNode;
    title: string;
    content?: ReactNode;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    width?: string;
    onConfirm: () => void;
    onCancel?: () => void;
};

const Confirm = (props: ConfirmProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const { title, content, confirmButtonLabel, cancelButtonLabel, children, width, onConfirm, onCancel } = props;

    const handleCancel = () => {
        setIsOpen(false);
        onCancel?.();
    };

    const handleConfirm = () => {
        setIsOpen(false);
        onConfirm();
    };

    return (
        <>
            <div onClick={() => setIsOpen(true)}>{children}</div>
            {isOpen && (
                <Dialog open={isOpen} onClose={onCancel}>
                    <div style={{ width }} data-testid='confirm-dialog'>
                        <DialogTitle className={styles.title}>{title}</DialogTitle>
                        {content && <DialogContent>{content}</DialogContent>}
                        <DialogActions className={styles.actions}>
                            <SecondaryButton onClick={handleCancel}>{cancelButtonLabel || 'Cancel'}</SecondaryButton>
                            <PrimaryButton onClick={handleConfirm}>{confirmButtonLabel || 'Confirm'}</PrimaryButton>
                        </DialogActions>
                    </div>
                </Dialog>
            )}
        </>
    );
};

export default Confirm;
