import React from 'react';

import { useAppDispatch, useAppSelector } from 'src/store';
import { getUserManagementGeneralUiState } from 'src/app/admin/views/user-management/state/userManagementSelector';

import styles from './UserManagementList.module.scss';
import { reset, updateGeneralUiState } from '../../state/userManagementSlice';
import NewProjectUser from '../new-project-user/NewProjectUser';
import { EditProjectUser } from '../edit-project-user/EditProjectUser';

import { ViewProjectUser } from '../view-project-user/ViewProjectUser';
import SnackbarAlert from 'src/shared/components/snackbar-alert/SnackbarAlert';
import Modal from 'src/shared/components/modal/Modal';

const UserManagementModals = () => {
    const dispatch = useAppDispatch();
    const generalUiState = useAppSelector(getUserManagementGeneralUiState);

    const resetForm = () => {
        dispatch(reset());
    };

    return (
        <div>
            {generalUiState.shouldShowNewUserModal && (
                <Modal classNames={{ body: styles.newUserModal }} onClose={resetForm}>
                    <NewProjectUser />
                </Modal>
            )}
            {generalUiState.shouldShowEditUserModal && (
                <Modal classNames={{ body: styles.newUserModal }} onClose={resetForm}>
                    <EditProjectUser />
                </Modal>
            )}

            {generalUiState.shouldShowViewUserModal && (
                <Modal classNames={{ body: styles.newUserModal }} onClose={resetForm}>
                    <ViewProjectUser />
                </Modal>
            )}

            {generalUiState.shouldShowSuccessAlert && (
                <SnackbarAlert
                    message={generalUiState.successStatusMessage}
                    open={generalUiState.shouldShowSuccessAlert}
                    onClose={() => dispatch(updateGeneralUiState({ shouldShowSuccessAlert: false }))}
                    autoHideDuration={6000}
                />
            )}
        </div>
    );
};

export default UserManagementModals;
