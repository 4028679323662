import React, { useEffect, useState } from 'react';

import { ReactComponent as VideoIcon } from 'src/assets/svg/video_black_24.svg';

import styles from './VideoHelp.module.scss';
import HelpPopup from 'src/shared/components/help-popup/HelpPopup';
import { useLocation } from 'react-router-dom';
import cmsService from 'src/services/cms-service';
import classNames from 'classnames';

const VideoHelp = () => {
    const { pathname } = useLocation();
    const [articleContent, setArticleContent] = useState<string | null>(null);
    const [isPopupOpen, setPopupOpen] = useState(false);

    useEffect(() => {
        const fetchArticleContent = async () => {
            if (pathname.length > 2 && pathname.includes('login')) {
                return;
            }

            const slug = 'video-article' + pathname.replaceAll('/', '-');
            try {
                const article = await cmsService.getArticleBySlug(slug);
                if (article) {
                    setArticleContent(article?.content);
                }
            } catch (error: any) {
                if (error.status !== 404) {
                    console.error('Failed to fetch article content:', error);
                }
            }
        };
        fetchArticleContent();

        return () => {
            setArticleContent(null);
        };
    }, [pathname]);

    if (!articleContent) {
        return null;
    }

    const iconPlacement = pathname.startsWith('/map') ? 'top' : 'Bottom';

    const wrapperClassNames = classNames(styles.videoHelp, {
        [styles.videoHelpTop]: iconPlacement === 'top',
        [styles.videoHelpOpen]: isPopupOpen,
    });

    const videoIcon = (
        <div className={styles.videoHelpController}>
            <div className={styles.videoHelpControllerText}>Watch video</div>
            <div className={styles.videoHelpControllerIcon}>
                <VideoIcon />
            </div>
        </div>
    );
    return (
        <div className={wrapperClassNames}>
            <HelpPopup label={videoIcon} onClose={() => setPopupOpen(false)} onOpen={() => setPopupOpen(true)}>
                <div className={styles.videoHelpContent}>
                    <div dangerouslySetInnerHTML={{ __html: articleContent }}></div>
                </div>
            </HelpPopup>
        </div>
    );
};

export default VideoHelp;
