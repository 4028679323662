import React, { forwardRef, InputHTMLAttributes, ForwardedRef, useState } from 'react';
import classNames from 'classnames';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import Input from '../input/Input';
import styles from './PasswordInput.module.scss';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
    hasError?: boolean;
};

const PasswordInput = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const { className: classNameFromProps, ...otherProps } = props;

    const [visibilityStatus, setVisibilityStatus] = useState(false);

    const className = classNames(styles.passwordInput, classNameFromProps);

    const onVisibilityIconToggle = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (otherProps.disabled) {
            return;
        }
        setVisibilityStatus(!visibilityStatus);
        const inputElement = event.currentTarget.parentElement?.querySelector('input');
        // Set the focus back to the input element
        inputElement?.focus();
    };

    return (
        <div className={className} data-testid='password-input'>
            <Input {...otherProps} className={styles.input} ref={ref} type={visibilityStatus ? '' : 'password'} />
            <span className={styles.visibilityIcon} onClick={onVisibilityIconToggle}>
                {visibilityStatus ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
            </span>
        </div>
    );
};

export default forwardRef(PasswordInput);
