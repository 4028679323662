import React, { ReactNode } from 'react';
import MUISelect, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';

import MenuItem from '@mui/material/MenuItem';
import styles from './SelectMultiple.module.scss';
import { ListItemText } from '@mui/material';
import classNames from 'classnames';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
        },
    },
    MenuListProps: {
        style: {
            paddingBottom: 0,
        },
    },
    anchorOrigin: {
        vertical: 'bottom' as const,
        horizontal: 'left' as const,
    },
    transformOrigin: {
        vertical: 'top' as const,
        horizontal: 'left' as const,
    },
    getcontentanchorel: null,
    classes: {
        list: styles.list,
    },
};

export type SelectOption = {
    value: string;
    label: ReactNode;
};

type SelectProps = {
    options: SelectOption[];
    onChange: (value: string[]) => void;
    values: string[];
    label?: string;
    className?: string;
    width?: number | string;
    optionsWidth?: number | string;
    showClearButton?: boolean;
};

const SelectMultiple = (props: SelectProps) => {
    const { options, onChange, values, label, width = 150, optionsWidth, showClearButton = false } = props;

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        onChange(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const containerClassNames = classNames(styles.selectContainer, props.className);
    return (
        <div className={containerClassNames}>
            <FormControl size='small' sx={{ width }}>
                {label && <InputLabel id='select-multiple'>{label}</InputLabel>}
                <MUISelect
                    labelId='select-multiple'
                    multiple
                    value={values}
                    onChange={handleChange}
                    input={<OutlinedInput label={label} />}
                    renderValue={selected =>
                        !selected.length ? 'None selected' : `${selected.length} ${selected.length > 1 ? 'values' : 'value'} selected`
                    }
                    displayEmpty
                    MenuProps={menuProps}
                    fullWidth
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option.value} style={{ backgroundColor: 'white', width: optionsWidth }}>
                            <Checkbox checked={values.indexOf(option.value) > -1} />
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))}
                    <div className={styles.selectFooter}>
                        <span className={styles.selectAll} onClick={() => onChange(options.map(option => option.value))}>
                            Select all
                        </span>
                        {showClearButton && (
                            <span className={styles.clear} onClick={() => onChange([])}>
                                Clear
                            </span>
                        )}
                    </div>
                </MUISelect>
            </FormControl>
        </div>
    );
};

export default SelectMultiple;
