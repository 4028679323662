import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AdminUserType } from 'src/shared/types';
import { useAppSelector } from 'src/store';
import { getNewProjectUserFormStep } from 'src/app/admin/views/user-management/state/userManagementSelector';
import UserDetailsForm from './components/user-details-form/UserDetailsForm';
import UserProjectsForm from './components/user-projects-form/UserProjectsForm';
import FormNavigation from './components/form-navigation/FormNavigation';
import useAuthService from 'src/shared/hooks/useAuthService';
import styles from './NewProjectUser.module.scss';

const pageText = {
    hint: {
        [AdminUserType.ADMIN]: 'Saving a new user will send them an email with an invitation to set up their account with NatureMetrics',
        [AdminUserType.SUPER_ADMIN]:
            'Adding a new user will send them an email with an invitation to set up their account with NatureMetrics for this organisation',
    },
};

const NewProjectUser = () => {
    const { isCurrentUserOnboardingManager, isCurrentUserSuperAdmin } = useAuthService();

    const newUserView = useAppSelector(getNewProjectUserFormStep);

    const sectionType = isCurrentUserOnboardingManager || isCurrentUserSuperAdmin ? AdminUserType.SUPER_ADMIN : AdminUserType.ADMIN;

    const saveHint = (
        <div className={styles.saveHint}>
            <div className={styles.saveHintIcon}>
                <InfoOutlinedIcon />
            </div>
            <div className={styles.saveHintText}>{pageText.hint[sectionType]}</div>
        </div>
    );

    return (
        <div className={styles.newProjectUser}>
            <div className={styles.title}>Add user</div>
            {newUserView === 'default' && <UserDetailsForm />}
            {newUserView === 'project-permissions' && (
                <>
                    <UserProjectsForm />
                    {saveHint}
                </>
            )}
            <FormNavigation />
        </div>
    );
};
export default NewProjectUser;
