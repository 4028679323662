import React, { useRef } from 'react';
import classNames from 'classnames';

import useFileDrop from './hooks/useFileDrop';
import { transformFiles, transformFile } from './helpers/uploadHelpers';
import type { FileUploadParams, Options, Result } from './types';

import styles from './Upload.module.scss';
import { PrimaryButton } from '../button/Button';

export type UploadProps<T extends Options> = FileUploadParams<T> & {
    name?: string;
    label?: string;
    disabled?: boolean;
};

const defaultLabel = 'Drag and drop a file here';

const Upload = <T extends Options>(props: UploadProps<T>) => {
    const { allowMultiple, transformTo, accept } = props;
    const { ref, isDraggedOver } = useFileDrop(props);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleSelectedFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = [...(event.target.files as FileList)].filter(file => {
            if (accept && file.type === '') {
                // Fallback filename extension check if type is empty
                const extension = new RegExp(accept.split(',').join('|'));
                if (extension.test(file.name.toLocaleLowerCase())) {
                    return true;
                }
            } else {
                if (!accept || accept.split(',').includes(file.type)) return true;
            }
        });

        if (files.length > 0) {
            if (transformTo) {
                const result = allowMultiple ? await transformFiles(files, transformTo) : await transformFile(files[0], transformTo);
                props.onUpload(result as Result<T>);
            } else if (allowMultiple) {
                props.onUpload([...files] as Result<T>);
            } else {
                props.onUpload(files[0] as Result<T>);
            }
        }

        clearInput();
    };

    const clearInput = () => {
        const inputElement = inputRef.current;
        if (inputElement) {
            inputElement.value = '';
        }
    };

    const dropAreaClasses = classNames(
        styles.upload,
        { [styles.uploadDisabled]: props.disabled },
        { [styles.uploadDragOver]: isDraggedOver }
    );

    return (
        <label ref={ref} className={dropAreaClasses}>
            <span className={styles.label}>{props.label || defaultLabel}</span>
            <input
                type='file'
                ref={inputRef}
                accept={accept}
                name={props.name}
                className={styles.fileInput}
                onChange={e => handleSelectedFiles(e)}
                multiple={props.allowMultiple}
            />
            {props.label}
            <div>OR</div>
            <PrimaryButton>Browse files</PrimaryButton>
        </label>
    );
};

export default Upload;
