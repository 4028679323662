import React from 'react';

import Loader from 'src/shared/components/loader/Loader';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import { ProgressStatus, ProjectDefinition, Access, Resources } from 'src/shared/types';
import NoData from 'src/shared/components/no-data/NoData';
import NodataImage from 'src/assets/svg/projectlist-nodata.svg?url';
import styles from './ProjectList.module.scss';
import usePermissions from 'src/shared/hooks/usePermissions';
import useProject from 'src/shared/hooks/useProject';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { SecondaryButton } from 'src/shared/components/button/Button';
import ProjectListItem from './components/project-list-item/ProjectListItem';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

export const ProjectsList = () => {
    const { currentCustomerProjects, isFetchingCurrentCustomerProjects } = useProject();
    const navigate = useAppNavigation();
    const { hasPermission } = usePermissions();

    const { currentCustomerId } = useCustomer();

    const canAdminProject = hasPermission({
        to: [Access.CREATE, Access.UPDATE],
        customerId: currentCustomerId,
        resource: Resources.PROJECT_DEFINITION,
    });
    const canAdminSurveyDesign = hasPermission({
        to: [Access.CREATE, Access.UPDATE],
        customerId: currentCustomerId,
        resource: Resources.SURVEY_DESIGN,
    });

    const title = 'Project Portfolio';
    const subTitle = 'Select a project to explore nature performance and insights';

    if (isFetchingCurrentCustomerProjects) {
        return <Loader />;
    }

    const filteredProjects = currentCustomerProjects?.filter(project =>
        [project.status, project.survey?.status].includes(ProgressStatus.COMPLETED)
    );

    const mainContent = filteredProjects?.length ? (
        <div className={styles.projectCardsList}>
            {filteredProjects.map((project: ProjectDefinition) => {
                return <ProjectListItem projectData={project} key={project?.projectId} />;
            })}
        </div>
    ) : (
        <div className={styles.emptyMessagetext}>
            <NoData
                image={NodataImage}
                text=''
                subText='No projects have been created yet. Please speak to our Customer Success team if you are having an issue.'
                height='40vh'
            />
        </div>
    );

    const otherActions = (
        <div className={styles.otherActions}>
            {canAdminSurveyDesign && <SecondaryButton onClick={navigate.toSurveyAdmin}>Go to survey design admin</SecondaryButton>}
            {canAdminProject && <SecondaryButton onClick={navigate.toProjectAdmin}>Go to project admin</SecondaryButton>}
        </div>
    );

    return <StandardAppLayout mainContent={mainContent} title={title} subTitle={subTitle} otherActions={otherActions} />;
};

export default ProjectsList;
