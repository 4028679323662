import React from 'react';
import classNames from 'classnames';
import { SpeciesListTableData } from '../species-list-table/SpeciesListTable';
import styles from './IucnStatusChips.module.scss';
import useFeatures from 'src/shared/hooks/useFeatures';

type IucnStatusChipProps = Pick<SpeciesListTableData, 'iucnStatusType' | 'isInvasive' | 'iucnStatusLabel'> & {
    shortedLabel?: boolean;
};

const IucnStatusChips = (props: IucnStatusChipProps) => {
    const { iucnStatusType, isInvasive, iucnStatusLabel, shortedLabel = false } = props;
    const { shouldShowInvasivesContent, shouldShowIucnRedlistContent } = useFeatures();

    return (
        <>
            {shouldShowIucnRedlistContent && iucnStatusType && (
                <span className={classNames([styles.chip, styles[iucnStatusType]])}>{iucnStatusLabel}</span>
            )}
            &nbsp;
            {shouldShowInvasivesContent && isInvasive && (
                <span className={classNames([styles.chip, styles.invasive])}>{shortedLabel ? 'INV' : 'Invasive'}</span>
            )}
        </>
    );
};

export default IucnStatusChips;
