import React, { useState, useEffect } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PrimaryButton } from 'src/shared/components/button/Button';
import BannerTooltip from './components/banner-tooltip/BannerTooltip';

import useProject from 'src/shared/hooks/useProject';
import cmsService from 'src/services/cms-service';
import useDemoProjectAnalytics from './hooks/useDemoProjectAnalytics';

import styles from './DemoProjectBanner.module.scss';
import { useGetRegisterationStatusQuery, useCreateRegisterInterestMutation } from './state/api/demoProjectBannerGraphSlice';
import Check from '@mui/icons-material/Check';

type DemoProjectBannerProps = {
    pageName: string;
};

const DemoProjectBanner = ({ pageName }: DemoProjectBannerProps) => {
    const [isDemoProject, setIsDemoProject] = useState<boolean>(false);
    const [isInterested, setInterested] = useState<boolean>(false);
    const [registerInterest] = useCreateRegisterInterestMutation();
    const { currentProjectId } = useProject();
    const { trackInterestRegistration } = useDemoProjectAnalytics();

    useEffect(() => {
        if (!currentProjectId) {
            return;
        }

        const fetchCmsContent = async () => {
            const { isDemo } = await cmsService.getProjectById(currentProjectId);
            setIsDemoProject(isDemo);
        };
        fetchCmsContent();
    }, [currentProjectId]);

    const { currentData: registrationStatusData, refetch } = useGetRegisterationStatusQuery(null, {
        skip: !isDemoProject,
    });

    useEffect(() => {
        if (!registrationStatusData?.haveRegisteredMyInterest) {
            return;
        }
        setInterested(registrationStatusData?.haveRegisteredMyInterest);
    }, [registrationStatusData]);

    if (!isDemoProject || !pageName || !registrationStatusData) {
        return null;
    }

    const handleInterestRequest = async () => {
        if (isInterested) {
            return;
        }
        try {
            await registerInterest(null).unwrap();
            await refetch();
            setInterested(true);
            trackInterestRegistration(pageName);
        } catch (error) {
            console.error('rejected', error);
        }
    };

    const actionText = isInterested ? 'Interest registered' : 'Register your interest';
    const actionIcon = isInterested ? (
        <span>
            <Check style={{ marginTop: '9px' }} />
        </span>
    ) : (
        <span>&gt;</span>
    );

    return (
        <div className={styles.bannerContainer}>
            <div className={styles.messageBox}>
                <InfoOutlinedIcon />
                <div className={styles.messageBoxContent}>
                    <div className={styles.message}>This is a demo project from an insights subscription with NatureMetrics</div>
                    <div className={styles.description} data-testid='banner-tooltip'>
                        <BannerTooltip pageName={pageName} />
                    </div>
                </div>
            </div>
            <div className={styles.interestBox}>
                <div>
                    Interested in unlocking the value of our <br />
                    Insights subscription for your organisation?
                </div>
                <div className={styles.buttonBar}>
                    <PrimaryButton
                        className={styles.createUserButton}
                        onClick={handleInterestRequest}
                        disabled={isInterested ? true : false}
                    >
                        {actionText}
                        {actionIcon}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default DemoProjectBanner;
