import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import { getCurrentCustomerId, getCurrentCustomerDetails } from 'src/app/customers/state/customersSelector';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState, TableCellRendererParams, TableRowData } from 'src/shared/components/data-table/dataTableTypes';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader from 'src/shared/components/loader/Loader';
import { PrimaryButton } from 'src/shared/components/button/Button';
import AddIcon from '@mui/icons-material/Add';
import Input from 'src/shared/components/input/Input';
import SearchIcon from '@mui/icons-material/Search';
import { ProjectDefinition, Status, CMSHabitatAssayType, Access, Resources } from 'src/shared/types';
import styles from './ProjectAdmin.module.scss';
import { setCurrentProjectId } from '../project-list/state/projectListSlice';
import StatusIndicator from 'src/shared/components/status-indicator/StatusIndicator';
import { setFormState as setNewProjectDefinitionFormState } from '../new-project-definition/state/newProjectDefinitionSlice';
import usePermissions from 'src/shared/hooks/usePermissions';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

interface NavigationStateProps {
    sourcePage: string;
}

const getProjectStatusFromEnum = (status: string) => {
    switch (status) {
        case 'COMPLETED':
            return Status.SUCCESS;
        case 'PENDING':
            return Status.WARNING;
        default:
            return Status.UNKNOWN;
    }
};

const filterAndSortProjects = (projects: ProjectDefinition[]) => {
    return projects
        .filter(project => project.status === 'PENDING')
        .sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())
        .concat(
            projects
                .filter(project => project.status === 'COMPLETED')
                .sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())
        );
};

const ProjectAdmin = () => {
    const customerId = useAppSelector(getCurrentCustomerId) || '';
    const currentCustomerDetails = useAppSelector(getCurrentCustomerDetails);
    const title = 'Project Admin';
    const subTitle = 'Manage my project definitions';
    const navigate = useAppNavigation();
    const dispatch = useAppDispatch();
    const { hasPermission } = usePermissions();
    const [columns, setColumns] = useState<DataTableColumns>([]);
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        fixedHeader: true,
        hiddenColumnIds: new Set(['projectId', 'creatorName']),
    });
    const [searchQuery, setSearchQuery] = useState<string>('');
    const location = useLocation();
    const navigationState = location.state as NavigationStateProps;

    const { isFetching, currentData } = useProjectsQuery(
        {
            customerId,
        },
        {
            skip: !customerId,
        }
    );

    useEffect(() => {
        dispatch(setCurrentProjectId(null));
    }, []);

    const canCreateProject = hasPermission({
        to: [Access.CREATE],
        customerId: customerId,
        resource: Resources.PROJECT_DEFINITION,
    });

    useEffect(() => {
        if (!currentData) {
            return;
        }

        const columns: DataTableColumns = [
            {
                columnId: 'projectId',
                title: 'Id',
            },
            {
                columnId: 'projectName',
                title: 'Name',
                width: '15%',
            },
            {
                columnId: 'country',
                title: 'Sampling country',
                width: '15%',
            },
            {
                columnId: 'status',
                title: 'Status',
                width: '15%',
                renderer: (params: TableCellRendererParams) => {
                    const status = params.cellData as string;
                    const createdAt = params.rowData[5] as string;
                    const statusText = status === 'PENDING' ? 'Incomplete' : 'Completed';

                    return (
                        <div className={styles.statusCell}>
                            <StatusIndicator status={getProjectStatusFromEnum(status)} statusText={statusText} />
                            {getProjectStatusFromEnum(status) !== Status.SUCCESS && (
                                <p>Expires at {moment(createdAt).add(5, 'days').format('DD MMM, HH:mm A')}</p>
                            )}
                        </div>
                    );
                },
            },
            {
                columnId: 'projectType',
                title: 'Test',
                width: '35%',
            },
            {
                columnId: 'createdAt',
                title: 'Created',
                width: '20%',
                renderer: (params: TableCellRendererParams) => {
                    const creatorName = params.rowData[6] as string;
                    return (
                        <>
                            <p>{moment(params.cellData as string).format('DD MMM YYYY')}</p>
                            <p className={styles.creatorName}>By {creatorName}</p>
                        </>
                    );
                },
            },
            {
                columnId: 'creatorName',
                title: 'creatorName',
            },
        ];

        setColumns(columns);

        const tableData: DataTableState['data'] = [];
        const projects = filterAndSortProjects(currentData?.customer?.projects || []);
        projects.forEach((project: ProjectDefinition) => {
            const { projectName, habitatAssay, country, createdAt, creatorName, projectId, status } = project;

            if (!!projectName && !!habitatAssay) {
                const currentRow = [];
                currentRow.push(projectId);
                currentRow.push(projectName);
                currentRow.push(country);
                currentRow.push(status);
                currentRow.push(habitatAssay.map((item: CMSHabitatAssayType) => `${item.habitatName} ${item.assayName}`).join(', '));
                currentRow.push(createdAt);
                currentRow.push(creatorName);

                tableData.push(currentRow);
            }
        });

        setTableState({
            ...tableState,
            data: tableData,
        });
    }, [currentData?.customer?.projects, navigationState?.sourcePage]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const queryString = e.target.value;
        setSearchQuery(queryString);
        setTableState({
            ...tableState,
            searchText: queryString,
        });
    };

    const onRowSelect = (rowData: TableRowData) => {
        const [projectId, projectName, , status] = rowData;

        if (status === 'PENDING') {
            navigate.toNewProjectDefinition();
            setTimeout(() => {
                dispatch(
                    setNewProjectDefinitionFormState({
                        projectId: projectId as string,
                        projectName: projectName as string,
                    })
                );
            }, 10);
            return;
        }
        dispatch(setCurrentProjectId(projectId as string));
        setTimeout(() => {
            navigate.toProjectDefinition();
        }, 1);
    };

    if (isFetching || !currentData || !tableState.data) {
        return <Loader />;
    }

    const createProjectDefinitionBtn = (
        <PrimaryButton onClick={navigate.toNewProjectDefinition} data-testid='create-new-proj-def-btn'>
            Create new project Definition &nbsp; <AddIcon />
        </PrimaryButton>
    );

    const customerDetails = (
        <div className={styles.customerInfo}>
            <div className={styles.header}>Customer:</div>
            <div className={styles.name} data-testid='customer-name'>
                {currentCustomerDetails?.customerName}
            </div>
        </div>
    );

    if (!currentData?.customer?.projects?.length) {
        const content = (
            <div className={styles.noDataContainer}>
                <div className={styles.content}>
                    <div>
                        <h4>No Projects</h4>
                    </div>
                    <div>
                        <span>You have not created or been assigned to any project definitions for this customer</span>
                    </div>
                    {canCreateProject && <div>{createProjectDefinitionBtn}</div>}
                </div>
            </div>
        );
        return <StandardAppLayout mainContent={content} title={title} subTitle={subTitle} otherActions={customerDetails} />;
    }

    const mainContent = (
        <div>
            <div className={styles.container}>
                <div className={styles.searchBar}>
                    <div className={styles.searchInputContainer}>
                        <span className={styles.searchIcon}>
                            <SearchIcon />
                        </span>
                        <Input
                            className={styles.searchInput}
                            value={searchQuery}
                            onChange={handleSearch}
                            placeholder='Search project definitions'
                        />
                    </div>
                </div>
                {canCreateProject && <div className={styles.button}>{createProjectDefinitionBtn}</div>}
            </div>
            <div>
                <DataTable state={tableState} columns={columns} onRowSelect={onRowSelect} />
            </div>
        </div>
    );

    return <StandardAppLayout mainContent={mainContent} title={title} subTitle={subTitle} otherActions={customerDetails} />;
};

export default ProjectAdmin;
