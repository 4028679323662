import React, { useEffect, useState } from 'react';

import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState } from 'src/shared/components/data-table/dataTableTypes';
import { dataTableDefaultDownloadHandler } from 'src/shared/components/data-table/helpers/downloadHandler';
import DownloadButton from 'src/shared/components/download-button/DownloadButton';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader, { LinearLoader } from 'src/shared/components/loader/Loader';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import useProject from 'src/shared/hooks/useProject';
import { useHabitatInsightsMetricsTableDataQuery } from '../state/api/tablesGraphSlice';
import { transformHabitatInsightsTableData } from './habitatInsightsMetricsTable.utils';
import { habitatInsightsLandCoverTypes } from 'src/shared/constants/habitatInsights';

import styles from './HabitatInsightsMetricsTable.module.scss';
import { HabitatInsightsChartType } from 'src/app/maps/views/habitat-insights/state/api/habitatInsightsGraphSlice';

const connectivityColumns: DataTableColumns = [
    {
        columnId: `grass-connectivity`,
        title: `Grass connectivity`,
    },
    {
        columnId: `grass-connectivity-av`,
        title: `Grass connectivity - Annual Variability`,
    },
    {
        columnId: `trees-connectivity`,
        title: `Trees connectivity`,
    },
    {
        columnId: `trees-connectivity-av`,
        title: `Trees connectivity - Annual Variability`,
    },
    {
        columnId: `all-green-connectivity`,
        title: `All Green connectivity`,
    },
    {
        columnId: `all-green-connectivity-av`,
        title: `All Green connectivity - Annual Variability`,
    },
];

const primaryProductivityColumns: DataTableColumns = [
    {
        columnId: `primary-productivity`,
        title: 'Primary Productivtiy',
    },
    {
        columnId: `primary-productivity-ar`,
        title: 'Primary Productivtiy - Annual Range',
    },
];

const PAGE_TITLE = 'Habitat insights metric table';
export const HabitatInsightsMetricsTable = () => {
    const { currentProjectDetails, currentProjectFilters } = useProject();
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);
    const [columns, setColumns] = useState<DataTableColumns>([]);
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        fixedHeader: true,
    });

    const { currentData, isFetching } = useHabitatInsightsMetricsTableDataQuery(
        {
            projectId: currentProjectDetails?.projectId || '',
            habitatInsightsCharts: [
                HabitatInsightsChartType.LAND_COVER,
                HabitatInsightsChartType.HABITAT_CONNECTIVITY,
                HabitatInsightsChartType.PRIMARY_PRODUCTIVITY,
            ],
            siteName: currentProjectFilters?.habitatInsightsSites || [],
        },
        {
            skip:
                !currentProjectDetails?.projectId ||
                !currentProjectFilters?.habitatInsightsChartTypes ||
                !currentProjectFilters?.habitatInsightsSites,
        }
    );

    useEffect(() => {
        const tableApiData = currentData?.habitatInsightsMetricsTableData;
        const habitatInsightsTableData: DataTableState['data'] = transformHabitatInsightsTableData(tableApiData?.data || []);

        if (habitatInsightsTableData.length === 0) return;

        const landCoverColumns: DataTableColumns = habitatInsightsLandCoverTypes.map(metric => {
            return {
                columnId: metric,
                title: `${metric} (%)`,
            };
        });

        setColumns([
            {
                columnId: 'Site',
                title: 'Site',
                width: '120px',
            },
            {
                columnId: 'Year',
                title: 'Year',
                width: '80px',
            },
            ...landCoverColumns,
            ...connectivityColumns,
            ...primaryProductivityColumns,
        ]);

        setTableState({
            ...tableState,
            data: habitatInsightsTableData,
        });
    }, [currentData]);

    if (isFetching) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    if (!currentProjectDetails || !currentData || !tableState.data) {
        return null;
    }

    const handleDownload = () => {
        if (!tableState.data) return;

        const downloadFileName = ['NM-', currentProjectDetails?.projectId, '.Habitat-Insights', '.Metrics', '.csv'].join('');

        dataTableDefaultDownloadHandler({
            data: tableState.data,
            columns,
            downloadFileName,
        });
    };

    const mainContent = (
        <div className={styles.tableWrapper}>
            <DataTable state={tableState} columns={columns} onStateChange={setTableState} className={styles.dataTable} />
        </div>
    );

    return (
        <StandardAppLayout
            mainContent={mainContent}
            otherActions={<DownloadButton onClick={handleDownload} className={styles.downloadButton} />}
            title={PAGE_TITLE}
            subTitle={<CMSArticle slug='habitat-insights-metrics-table-description' />}
            breadcrumbs={currentProjectBreadcrumbDetails}
        />
    );
};

export default HabitatInsightsMetricsTable;
