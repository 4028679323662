import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import { UserType } from 'src/shared/types';
import { getUserManagementListFilterState } from '../../state/userManagementSelector';
import {
    UserManagementListFilterState,
    resetUserManagementListFilterState,
    updateGeneralUiState,
    updaterUserManagementListFilterState,
} from '../../state/userManagementSlice';

import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store';
import Input from 'src/shared/components/input/Input';
import Select, { SelectOption } from 'src/shared/components/select/Select';

import useAuthService from 'src/shared/hooks/useAuthService';
import styles from './UserManagementFilters.module.scss';

const userStatusOptions: SelectOption[] = [
    { label: 'All', value: 'All' },
    { label: 'Deactivated', value: 'Inactive' },
    { label: 'Active', value: 'Active' },
    { label: 'Invited', value: 'Invited' },
];

const userTypeOptions: SelectOption[] = [
    { label: 'All', value: 'All' },
    { label: UserType.INTERNAL, value: UserType.INTERNAL },
    { label: UserType.CUSTOMER, value: UserType.CUSTOMER },
];

const UserManagementFilters = () => {
    const userManagementListFilterState = useAppSelector(getUserManagementListFilterState);

    const dispatch = useAppDispatch();
    const { isCurrentUserSuperAdmin } = useAuthService();

    const handleResetFilters = () => {
        dispatch(resetUserManagementListFilterState());
    };

    const onFilterChange = (fragment: Partial<UserManagementListFilterState>) => {
        dispatch(
            updaterUserManagementListFilterState({
                ...fragment,
            })
        );
    };

    const openCreateUserModal = () => {
        dispatch(
            updateGeneralUiState({
                shouldShowNewUserModal: true,
            })
        );
    };

    return (
        <div className={styles.userManagementFilters}>
            <div className={styles.filters}>
                <div className={styles.fieldContainer}>
                    <label>User search</label>
                    <div className={styles.searchInputContainer} style={{ width: '300px' }}>
                        <span className={styles.searchIcon}>
                            <SearchIcon />
                        </span>
                        <Input
                            className={styles.searchInput}
                            placeholder='Type a name'
                            value={userManagementListFilterState.userNameSearchText}
                            onChange={event => {
                                onFilterChange({
                                    userNameSearchText: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className={styles.fieldContainer}>
                    <label>Project</label>
                    <div className={styles.searchInputContainer} style={{ width: '379px' }}>
                        <span className={styles.searchIcon}>
                            <SearchIcon />
                        </span>
                        <Input
                            className={styles.searchInput}
                            placeholder='Type a project name'
                            value={userManagementListFilterState.projectNameSearchText}
                            onChange={event => {
                                onFilterChange({
                                    projectNameSearchText: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className={styles.fieldContainer}>
                    <label>Account Status</label>
                    <div className={styles.selectBox}>
                        <Select
                            width={130}
                            options={userStatusOptions}
                            selectedValue={userManagementListFilterState.userStatus}
                            onChange={value => {
                                onFilterChange({
                                    userStatus: value,
                                });
                            }}
                        />
                    </div>
                </div>

                {isCurrentUserSuperAdmin && (
                    <div className={styles.fieldContainer}>
                        <label>User type</label>
                        <div className={styles.selectBox}>
                            <Select
                                width={130}
                                options={userTypeOptions}
                                selectedValue={userManagementListFilterState.userType}
                                onChange={value => {
                                    onFilterChange({
                                        userType: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                )}

                <SecondaryButton className={styles.buttonReset} onClick={handleResetFilters}>
                    <span>Reset filters</span> <HighlightOffIcon />
                </SecondaryButton>
            </div>

            <PrimaryButton className={styles.createUserButton} onClick={openCreateUserModal}>
                Add user <AddIcon />
            </PrimaryButton>
        </div>
    );
};

export default UserManagementFilters;
