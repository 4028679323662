import React from 'react';
import Loader from 'src/shared/components/loader/Loader';
import { useCustomersQuery } from './state/api/customersGraphSlice';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import { setCurrentCustomerId } from './state/customersSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import NoData from 'src/shared/components/no-data/NoData';
import HexagonNoData from 'src/assets/images/customers-nodata.svg?url';
import styles from './Customers.module.scss';
import { batch } from 'react-redux';
import { setCurrentProjectId } from '../projects/views/project-list/state/projectListSlice';
import { getCurrentCustomerId } from './state/customersSelector';
import usePermissions from 'src/shared/hooks/usePermissions';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

const Customers = () => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigation();
    const currentCustomerId = useAppSelector(getCurrentCustomerId) || '';
    const { data, isFetching } = useCustomersQuery({});
    const title = 'Organisations';
    const subTitle = 'Select an organisation to view their projects with NatureMetrics';
    const { isCurrentUserInternal } = usePermissions();

    if (isFetching) {
        return <Loader />;
    }

    const onClickHanlder = (customerId: string) => {
        if (currentCustomerId !== customerId) {
            batch(() => {
                // dispatch(resetRoutes());
                dispatch(setCurrentCustomerId(customerId));
                dispatch(setCurrentProjectId(null));
            });
        }

        // Push the navigation to the end of the event loop to give it enough time for the previous dispatch to finish
        setTimeout(() => {
            navigate.toProjects({
                customerId,
            });
        }, 1);
    };
    let mainContent = null;

    if (!data?.customers) {
        mainContent = <NoData image={HexagonNoData} text=' No data to show.' />;
    } else {
        mainContent = (
            <div className={styles.container}>
                {data?.customers.map(customer => {
                    return (
                        <div key={customer.customerId} className={styles.card} onClick={() => onClickHanlder(customer.customerId)}>
                            <span className={styles.cardTitle}>{customer.customerName}</span>
                            {isCurrentUserInternal && <span className={styles.cardSubTitle}>Customer code: {customer.customerCode}</span>}
                        </div>
                    );
                })}
            </div>
        );
    }

    return <StandardAppLayout mainContent={mainContent} title={title} subTitle={subTitle} />;
};

export default Customers;
