import { Action, createAsyncThunk, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { fetchProject } from './api/projectListGraphSlice';
import { RootState } from 'src/store';
import projectsStorageService from 'src/app/projects/services/projects-storage-service';
import { SelectedDateRange } from 'src/shared/components/filters/date-filter/DateFilter';
import moment from 'moment';
import uniq from 'lodash/uniq';
import { getQuarterDateRange } from 'src/shared/utils/dateHelpers';
import { HabitatAssay } from 'src/shared/types';

export type ProjectUIFiltersState = {
    selectedDateRange?: SelectedDateRange;
    habitatAssayType?: HabitatAssay;
};

export type ProjectUIState = {
    filters: ProjectUIFiltersState;
};

type ProjectListState = {
    currentProjectId: string | null;
    uiState: {
        [projectId: string]: ProjectUIState;
    };
};

const initialState: ProjectListState = {
    currentProjectId: null,
    uiState: {},
};

export const setCurrentProjectId =
    (project: string | null): ThunkAction<void, RootState, void, Action<string>> =>
    dispatch => {
        dispatch(projectListSlice.actions.setCurrentProjectId(project));

        projectsStorageService.setCurrentProjectId(project);
    };

export const loadStoredProjectId = createAsyncThunk('projects/loadStoredProjectId', async () => {
    return projectsStorageService.getCurrentProjectId();
});

export const resetProjects = (): ThunkAction<void, RootState, void, Action<string>> => dispatch => {
    dispatch(projectListSlice.actions.reset());

    projectsStorageService.clearAll();
};

const ensureProjectUIStatePresence = (state: ProjectListState, projectId: string) => {
    if (!state.uiState[projectId]) {
        state.uiState[projectId] = {
            filters: {},
        };
    }
};

const projectListSlice = createSlice({
    name: 'projectList',
    initialState,
    reducers: {
        setCurrentProjectId(state, action: PayloadAction<string | null>) {
            state.currentProjectId = action.payload;
        },

        setSelectedDateRange(state, action: PayloadAction<SelectedDateRange>) {
            if (state.currentProjectId) {
                state.uiState[state.currentProjectId].filters.selectedDateRange = action.payload;
            }
        },
        setHabitatAssayType(state, action: PayloadAction<HabitatAssay | undefined>) {
            if (state.currentProjectId) {
                ensureProjectUIStatePresence(state, state.currentProjectId);
                state.uiState[state.currentProjectId].filters.habitatAssayType = action.payload;
            }
        },

        reset(state) {
            // eslint-disable-next-line
            state = { ...initialState };
        },
    },
    extraReducers: builder => {
        builder.addCase(loadStoredProjectId.fulfilled, (state, action) => {
            state.currentProjectId = action.payload;
        });
        builder.addMatcher(fetchProject.matchFulfilled, (state, { payload }) => {
            if (state.currentProjectId && !state.uiState[state.currentProjectId]) {
                ensureProjectUIStatePresence(state, state.currentProjectId);

                const { samples } = payload.project;
                const uniqueSampleDates = uniq(samples.map(entry => moment(entry.sampleCollectedDatetime).startOf('Q').valueOf())).sort();

                const startDate = getQuarterDateRange(moment(uniqueSampleDates[0]).format('YYYY-MM-DD'))?.quarterStart;

                const endDate = getQuarterDateRange(moment(uniqueSampleDates[-1]).format('YYYY-MM-DD'))?.quarterEnd;

                state.uiState[state.currentProjectId] = {
                    filters: {
                        selectedDateRange: {
                            startDate: startDate || '',
                            endDate: endDate || '',
                        },
                    },
                };
            }
        });
    },
});

export default projectListSlice.reducer;

export const { setSelectedDateRange, setHabitatAssayType } = projectListSlice.actions;
