import React, { ReactNode, useEffect, useReducer, useRef } from 'react';
import classNames from 'classnames';

import Table from '../table/Table';
import TableBody from './components/main/components/table-body/TableBody';
import TableHeader from './components/main/components/table-header/TableHeader';
import TablePagination from './components/table-pagination/TablePagination';
import { defaultDataTableState, tableReducer } from './dataTableReducer';

import styles from './DataTable.module.scss';

import { type AllTableActions, type DataTableState, type DataTableColumns, type TableRows, TableRowData } from './dataTableTypes';

type DownloadHandler = (props: Pick<TableContextType, 'rows' | 'columns' | 'downloadFileName'>) => Promise<void>;

export type TableContextType = DataTableState & {
    dispatch: React.Dispatch<AllTableActions>;
    columns: DataTableColumns;
    emptyStateMessage?: ReactNode;
    downloadFileName?: string;
    downloadHandler?: DownloadHandler;
    rows: TableRows;
    onRowClick?: (rowData: TableRowData) => void;
};

export const TableContext = React.createContext(null as TableContextType | null);

export type TableProps = {
    state?: Partial<DataTableState>;
    emptyStateMessage?: ReactNode;
    columns: DataTableColumns;
    className?: string;
    downloadFileName?: string;
    downloadHandler?: DownloadHandler;
    onStateChange?: (newState: DataTableState) => void;
    onRowSelect?: (rowData: TableRowData) => void;
};
const DataTable = (props: TableProps) => {
    const initialState = {
        ...defaultDataTableState,
        ...(props.state || {}),
    };

    const firstRenderRef = useRef(true);
    const shouldResetStateRef = useRef(true);

    const [tableState, dispatch] = useReducer(tableReducer, initialState);

    /*
        The useReducer used above does not update the tableState when the parent component updates the state.
        To fix this, we need to check if state property has changed by the parent and reset it if necessary.
    */
    useEffect(() => {
        if (shouldResetStateRef.current && !firstRenderRef.current) {
            dispatch({
                type: 'restore_defaults',
                payload: initialState,
            });
        }

        /* 
            Here we reset shouldResetStateRef to the initial value (true), so that the 
            dispatch above gets executed when the state from the parent changes
        */
        shouldResetStateRef.current = true;
    }, [props.state]);

    useEffect(() => {
        if (firstRenderRef.current) {
            props.onStateChange?.(tableState);

            /*
                The onStateChange call above will update a state in the parent component which will trigger a rerender.
                At this point, we do not want to reset the tableState in the useEffect above
                So to prevent it, we set shouldResetStateRef to false
            */
            shouldResetStateRef.current = false;
        }
        firstRenderRef.current = false;
    }, [tableState]);

    const wrapperClasses = classNames(styles.wrapper, props.className);

    const tableClasses = classNames(styles.table);

    return (
        <TableContext.Provider
            value={{
                ...tableState,
                dispatch,
                columns: props.columns,
                emptyStateMessage: props.emptyStateMessage,
                rows: tableState.data.map((row, index) => ({
                    rowId: index,
                    cells: row,
                })),
                downloadFileName: props.downloadFileName,
                downloadHandler: props.downloadHandler,
                onRowClick: (rowData: TableRowData) => props.onRowSelect?.(rowData),
            }}
        >
            <div className={wrapperClasses} data-testid='datatable'>
                <div className={styles.tableContainer}>
                    <Table className={tableClasses} stickyHeader={true}>
                        <TableHeader />
                        <TableBody />
                    </Table>
                </div>
                <TablePagination />
            </div>
        </TableContext.Provider>
    );
};

export default DataTable;
