import React from 'react';
import styles from './StatusIndicator.module.scss';
import { Status } from 'src/shared/types';

export type StatusIndicatorProps = {
    status: Status;
    statusText: string;
};

export const StatusIndicator = (props: StatusIndicatorProps) => {
    const { status, statusText } = props;

    return (
        <div className={styles.status}>
            <span className={`${styles.statusIndicatorIcon} ${styles['statusIndicatorIcon' + status]}`}></span>
            <span className={`${styles.statusIndicatorText} ${styles['statusIndicatorText' + status]}`}>{statusText}</span>
        </div>
    );
};

export default StatusIndicator;
