import React from 'react';
import useHabitatInsightsUpload from 'src/app/surveys/views/habitat-insights-upload/hooks/useHabitatInsightsUpload';
import HabitatInsightsSamplesUpload from './components/habitat-insights-samples-upload/HabitatInsightsSamplesUpload';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import SuccessMessage from 'src/shared/components/success-message/SuccessMessage';
import styles from './HabitatInsightsSamples.module.scss';
import commonStyles from 'src/app/surveys/views/habitat-insights-upload/HabitatInsightsUpload.module.scss';

export const HabitatInsightsSamples = () => {
    const { formState } = useHabitatInsightsUpload();

    const { uploadedFileErrors, uploadedFileContent } = formState;

    return (
        <div className={commonStyles.formView}>
            <HabitatInsightsSamplesUpload />
            <div className={styles.statusMessages}>
                {uploadedFileContent.length > 0 ? (
                    uploadedFileErrors.length > 0 ? (
                        <ul>
                            {uploadedFileErrors.map((error, index) => (
                                <ErrorMessage key={index} message={<span dangerouslySetInnerHTML={{ __html: error.message }} />} />
                            ))}
                        </ul>
                    ) : (
                        <SuccessMessage message={'Data successfully uploaded with no errors'} />
                    )
                ) : null}
            </div>
        </div>
    );
};

export default HabitatInsightsSamples;
