import React, { ReactNode, useEffect, useState } from 'react';
import { uniqBy, mapValues } from 'lodash';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Cell, LabelList, CartesianGrid, Legend, Tooltip } from 'recharts';
import CustomLegend, { LegendDataProps } from '../../custom-legend/CustomLegend';
import styles from './HorizontalBarChart.module.scss';

export type HorizontalBarChartData = {
    name: string;
    value: number;
    color: string;
    type: string;
};

type HorizontalBarChartProps = {
    data: HorizontalBarChartData[];
    title: ReactNode;
    yAxisTile?: string;
};

type HoverProps = { [key: string]: boolean | string | null };

const HorizontalBarChart = ({ data, title, yAxisTile }: HorizontalBarChartProps) => {
    const [hoverProps, setHoverProps] = useState<HoverProps>({});
    const [legendData, setLegendData] = useState<LegendDataProps[]>([]);
    const [chartData, setChartData] = useState<HorizontalBarChartData[]>([...data]);
    const [selectedSampleGroups, setSelectedSampleGroups] = useState<string[]>([]);

    useEffect(() => {
        const groupsData = uniqBy(data, 'type');
        const newHoverProps: HoverProps = {};
        groupsData.forEach(item => {
            newHoverProps[item.type] = false;
        });
        setLegendData([
            ...groupsData.map(item => {
                return {
                    label: item.type,
                    type: item.type,
                    color: item.color,
                };
            }),
        ]);
        setHoverProps({ ...newHoverProps });
        setChartData([...data]);
    }, [data]);

    useEffect(() => {
        setChartData([...data.filter(item => !selectedSampleGroups.includes(item.type))]);
    }, [selectedSampleGroups]);

    const handleLegendMouseEnter = (e: { value: string }) => {
        if (!hoverProps[e.value]) {
            setHoverProps({ ...hoverProps, hover: e.value });
        }
    };

    const handleLegendMouseLeave = () => {
        setHoverProps({ ...hoverProps, hover: null });
    };

    const onClickHandler = (e: { value: string }) => {
        selectedSampleGroups.includes(e.value)
            ? setSelectedSampleGroups(selectedSampleGroups.filter(item => item !== e.value))
            : setSelectedSampleGroups([...selectedSampleGroups, e.value]);
        setHoverProps({
            ...hoverProps,
            [e.value]: !hoverProps[e.value],
            hover: null,
        });
    };

    const resetSelected = () => {
        setHoverProps({ ...mapValues(hoverProps, () => false), hover: null });
        setSelectedSampleGroups([]);
        setChartData([...data]);
    };

    const chartContainerHeight = Math.max(chartData.length * 30, 590);

    return (
        <div className='container'>
            <>
                <span className={styles.titleContainer}>
                    {yAxisTile && <div className={styles.yAxisTile}>{yAxisTile}</div>}
                    {title && <div className={styles.title}>{title}</div>}
                </span>
                <ResponsiveContainer width='90%' height={chartContainerHeight}>
                    <BarChart data={chartData} layout='vertical' margin={{ top: 50, right: 50, left: 0, bottom: 0 }} maxBarSize={20}>
                        <XAxis type='number' tickLine={false} axisLine={false} scale='pow' fontSize={15} />
                        <YAxis
                            type='category'
                            width={200}
                            dataKey='name'
                            tickLine={false}
                            axisLine={false}
                            dx={-15}
                            interval={0}
                            fontSize={15}
                        />
                        <CartesianGrid vertical={true} horizontal={false} />
                        <Bar dataKey='value' isAnimationActive={false}>
                            {chartData.map((entry: HorizontalBarChartData, index: number) => (
                                <Cell
                                    fill={entry.color}
                                    key={index}
                                    fillOpacity={Number(hoverProps.hover === entry.type || !hoverProps.hover ? 1 : 0.3)}
                                />
                            ))}

                            <LabelList dataKey='value' position='right' fill='#212121' fontSize={17} />
                        </Bar>
                        <Tooltip cursor={false} />
                        <Legend
                            align='right'
                            verticalAlign='top'
                            layout='vertical'
                            wrapperStyle={{ marginRight: -72 }}
                            content={
                                <CustomLegend
                                    title='Sample Groups'
                                    hoverProps={hoverProps}
                                    legendData={legendData}
                                    onMouseOverHandler={handleLegendMouseEnter}
                                    onMouseOutHandler={handleLegendMouseLeave}
                                    resetHanlder={resetSelected}
                                    onClickHandler={onClickHandler}
                                />
                            }
                        />
                    </BarChart>
                </ResponsiveContainer>
            </>
        </div>
    );
};

export default HorizontalBarChart;
