import React from 'react';
import Pagination from 'src/shared/components/pagination/Pagination';
import useDataTable from '../../hooks/useDataTable';
import styles from './TablePagination.module.scss';

const TablePagination = () => {
    const { setPageNumber, currentPageNumber, filteredRows, rowsPerPage, rows } = useDataTable();

    const displayedRowsCount = filteredRows.length;
    const lastPageNumber = Math.ceil(displayedRowsCount / rowsPerPage) || 1;

    return (
        <div className={styles.tableControls}>
            Viewing rows {(currentPageNumber - 1) * rowsPerPage + 1} - {Math.min(currentPageNumber * rowsPerPage, rows.length)} of{' '}
            {rows.length}
            <Pagination onChange={setPageNumber} currentPageNumber={currentPageNumber} lastPageNumber={lastPageNumber} />
        </div>
    );
};

export default TablePagination;
