import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DateFilter, { SelectedDateRange } from 'src/shared/components/filters/date-filter/DateFilter';
import { SelectOption } from 'src/shared/components/select/Select';
import useProject from 'src/shared/hooks/useProject';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { useAppDispatch } from 'src/store';
import Metrics from './components/metrics/Metrics';
import { getDateQuarterSelectOption, getQuarterDateRange } from 'src/shared/utils/dateHelpers';
import { Loader, LinearLoader } from 'src/shared/components/loader/Loader';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import uniq from 'lodash/uniq';
import HabitatAssayLozenges from 'src/shared/components/lozenges/HabitatAssayLozenges';
import { AppName } from 'src/global/globalConfig';
import useDashboardAnalytics from './hooks/useDashboardAnalytics';
import { setSelectedDateRange } from '../projects/views/project-list/state/projectListSlice';

type ProjectDateOptionList = {
    startDateOptions: SelectOption[];
    endDateOptions: SelectOption[];
};

const DASHBOARD_TITLE = 'Nature Performance Dashboard';

const Dashboard = () => {
    const {
        currentProjectSelectedDateRange,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        currentProjectDetails,
        currentProjectFilters,
        setCurrentProjectHabitatAssayType,
        currentProjectSelectedHabitatAssayType,
    } = useProject();

    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(DASHBOARD_TITLE);

    const [projectDateOptionList, setProjectDateOptionList] = useState<ProjectDateOptionList>();

    const habitatAssays = currentProjectFilters?.habitatAssays;
    const isSingleTimepoint = !!habitatAssays?.find(entry => entry.key === currentProjectSelectedHabitatAssayType?.key)?.singleTimepoint;

    const { trackDateFilterChange } = useDashboardAnalytics();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!currentProjectDetails) {
            return;
        }

        const samples = currentProjectDetails.samples;

        const uniqueSampleDates = uniq(samples.map(entry => moment(entry.sampleCollectedDatetime).startOf('Q').valueOf()))
            .sort()
            .reverse();

        const newTimepointOptions = uniqueSampleDates.map(entry => getDateQuarterSelectOption(entry)).reverse();

        const endDateOptions = newTimepointOptions.map(entry => ({ ...entry, value: getQuarterDateRange(entry.value)?.quarterEnd || '' }));

        setProjectDateOptionList({
            startDateOptions: newTimepointOptions,
            endDateOptions: endDateOptions,
        });
    }, [currentProjectDetails]);

    if (!currentProjectDetails) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    const onDateRangeChange = (dateRange: SelectedDateRange) => {
        trackDateFilterChange(`${dateRange.startDate} - ${dateRange.endDate}`);
        dispatch(setSelectedDateRange(dateRange));
    };

    const lozenges = (
        <HabitatAssayLozenges
            options={currentProjectFilters?.habitatAssayTypeOptions || []}
            selectedValue={habitatAssayType?.key || ''}
            onChange={setCurrentProjectHabitatAssayType}
            app={AppName.DASHBOARD}
        />
    );
    const otherActions =
        !isSingleTimepoint && projectDateOptionList?.startDateOptions?.length ? (
            <DateFilter
                startDates={projectDateOptionList.startDateOptions}
                endDates={projectDateOptionList.endDateOptions}
                selectedDateRange={{
                    startDate: currentProjectSelectedDateRange?.startDate || projectDateOptionList.startDateOptions[0].value,
                    endDate:
                        currentProjectSelectedDateRange?.endDate ||
                        projectDateOptionList.endDateOptions[projectDateOptionList.endDateOptions.length - 1].value,
                }}
                onChange={onDateRangeChange}
            />
        ) : null;

    return (
        <StandardAppLayout
            lozenges={lozenges}
            mainContent={<Metrics />}
            breadcrumbs={currentProjectBreadcrumbDetails}
            title={DASHBOARD_TITLE}
            subTitle='Trends and indicators'
            otherActions={otherActions}
            page={AppName.DASHBOARD}
        />
    );
};

export default Dashboard;
