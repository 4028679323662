import React from 'react';
import { FileUpload } from '@mui/icons-material';
import styles from '../SurveySamples.module.scss';
import { FileTransformedToString, Upload } from 'src/shared/components/upload';
import useSurveyDesign from '../../../hooks/useSurveyDesign';

export const SurveySamplesUpload = () => {
    const { onFormChange } = useSurveyDesign();

    const onUpload = (file: FileTransformedToString) => {
        onFormChange({
            uploadedFileContent: file.content || '',
        });
    };

    return (
        <div className={styles.uploadWrapper}>
            <div className={styles.top}>
                <div className={styles.topLeft}>
                    <div className={styles.uploadIcon}>
                        <FileUpload />
                    </div>
                    <div className={styles.uploadTitle}>Upload data</div>
                    <div className={styles.uploadFileType}>Supported file type: .geojson</div>
                </div>
                <div className={styles.topRight}>
                    <div className={styles.uploadHelpLink}>How do I prepare my file for upload?</div>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.uploadInput}>
                    <Upload onUpload={onUpload} transformTo='text' accept='application/geo+json,.geojson' />
                </div>
            </div>
        </div>
    );
};

export default SurveySamplesUpload;
